import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase';
import { 
  setDoc, 
  doc, 
  getDoc, 
  serverTimestamp, 
  collection, 
  addDoc, 
  updateDoc,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SnowflakeIcon from '@mui/icons-material/AcUnit';

function SignUp() {
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [referrer, setReferrer] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const isUsernameValid = /^[a-zA-Z]+[0-9]|[0-9]+[a-zA-Z]/.test(username);
  
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Permitir solo letras (mayúsculas y minúsculas) y números
    const validInput = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    setUsername(validInput);
  };


  


  // Capturar el referidor desde la URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get('ref');
    if (refParam) {
      setReferrer(refParam.trim().toLowerCase());
    }

    // Fondo aleatorio
    const backgroundImages = [
      'https://img.freepik.com/fotos-premium/fondo-festivo-articulos-decoracion-navidad_9693-868.jpg',
      'https://img.freepik.com/fotos-premium/arbol-navidad-rojo-fondo-rojo-luces_955834-56394.jpg',
      'https://wallpapers.com/images/featured/3d-navidad-bw37ez6p9clxblpj.jpg',
      'https://www.todofondos.net/wp-content/uploads/72-fondos-de-navidad-azules.-wallpaper-hd-1080p-navidenos-1024x576.jpg',
    ];
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setBackgroundImage(backgroundImages[randomIndex]);
  }, [location]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const normalizedUsername = username.trim().toLowerCase();
      const normalizedFullName = fullName.trim().toLowerCase();
      const normalizedPhone = phone.trim();


      const phoneRegex = /^[1-9]\d{9,14}$/; // Ejemplo: 1234567890 (10 a 15 dígitos)
    if (!phoneRegex.test(normalizedPhone)) {
      setError('Por favor, introduce un número de teléfono válido. Debe contener entre 10 y 15 dígitos y no debe empezar con 0.');
      return;
    }


      // Verificar si el número de teléfono ya está registrado
    const usersQuery = collection(db, 'users');
    const snapshot = await getDocs(query(usersQuery, where('phone', '==', normalizedPhone)));
    if (!snapshot.empty) {
      setError('El número de teléfono ya está en uso.');
      return;
    }

      // Guardar los datos del nuevo usuario
      const userDocRef = doc(db, 'users', normalizedUsername);

      await setDoc(userDocRef, {
        fullName: normalizedFullName,
        username: normalizedUsername,
        phone: normalizedPhone,
        password,
        createdAt: serverTimestamp(),
        avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/otra-151ec.appspot.com/o/avatars%2Favatar-defaul.png?alt=media&token=75f978f8-d5a4-4890-b898-33e353bd810f',
        online: false,
        firstTime: true,
        referrer,
        monOro: 0,
        isBlocked: false,
        numerologo: false, // muestra el formulario para enviar los 3 umeros
        numVip:false,    // muestra el boton VIP
        role: 'normal',

      });

// Registrar en CoinHistory del nuevo usuario
const userCoinHistoryRef = collection(db, `users/${normalizedUsername}/CoinHistory`);
await addDoc(userCoinHistoryRef, {
  type: 'Credit',
  amount: 0,
  timestamp: serverTimestamp(),
  description: `Tu cuenta está lista, gracias por registrarte a través de ${referrer}.`,
});


     // Si existe un referidor, acreditar la moneda y registrar en su CoinHistory
if (referrer) {
  const referrerDocRef = doc(db, 'users', referrer);
  const referrerDoc = await getDoc(referrerDocRef);

  if (referrerDoc.exists()) {
    const referrerData = referrerDoc.data();

    // Referencia al documento de la tabla fondos en la colección sistema
    const sistemaFondosRef = doc(db, 'sistema', 'fondos');
    const sistemaFondosDoc = await getDoc(sistemaFondosRef);


    const sistemaFondosData = (await getDoc(sistemaFondosRef)).data();
    const sistemaFondosActuales = sistemaFondosData.fondos || 0;

    // Validar si hay fondos suficientes en el sistema
    if (sistemaFondosActuales < 0.4) {
      throw new Error('Fondos insuficientes en el sistema para realizar la transacción.');
    }

    // Debitar 0.4 monedas del sistema
    await updateDoc(sistemaFondosRef, {
      fondos: sistemaFondosActuales - 0.4,
    });

    // Acreditar 0.4 monedas al referidor
    const updatedMonedasReferidor = (referrerData.monOro || 0) + 0.4;
    await updateDoc(referrerDocRef, { monOro: updatedMonedasReferidor });

    // Registrar en el historial del referidor
const referrerCoinHistoryRef = collection(db, `users/${referrer}/CoinHistory`);
await addDoc(referrerCoinHistoryRef, {
  type: 'Deposit',
  amount: 0.4,
  timestamp: serverTimestamp(),
  relatedUser: 'Sistema', // Indica que la transacción fue realizada por el sistema
  description: `Ganaste 0.4 monedas porque ${normalizedUsername} se inscribió a través de tu enlace.`,
});
  }
}

      setError('');
      localStorage.setItem('username', normalizedUsername);
      navigate('/chat');
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      setError(error.message);
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          p: 4,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 3,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          position: 'relative',
        }}
      >
        <HolidayVillageIcon
          sx={{
            fontSize: 60,
            color: 'green',
            position: 'absolute',
            top: -30,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: '#d32f2f',
            fontFamily: 'Pacifico, cursive',
          }}
        >
          ¡Regístrate y Únete!
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleSignUp} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="fullName"
            label="Nombre Completo"
            name="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            autoFocus
            sx={{ bgcolor: 'white', borderRadius: 1 }}
          />
        
        <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Nombre de Usuario"
        name="username"
        value={username}
        onChange={(e) => {
          const value = e.target.value;
          if (/^[a-zA-Z0-9]*$/.test(value)) {
            setUsername(value); // Permitir solo letras y números
          }
        }}
        error={username.length > 0 && !isUsernameValid}
        helperText={
          username.length > 0 && !isUsernameValid
            ? 'El nombre debe contener letras y números.'
            : ''
        }
        sx={{ bgcolor: 'white', borderRadius: 1 }}
      />

  

         <TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  id="phone"
  label="Número de teléfono"
  name="phone"
  value={phone}
  onChange={(e) => {
    // Elimina guiones y espacios en blanco
    const sanitizedValue = e.target.value.replace(/[-\s]/g, '');
    setPhone(sanitizedValue);
  }}
  sx={{ bgcolor: 'white', borderRadius: 1 }}
/>
<TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  id="password"
  label="Clave (mínimo 6 dígitos)"
  name="password"
  type="password"
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  inputProps={{ minLength: 6 }}
  error={password.length > 0 && password.length < 6}
  helperText={
    password.length > 0 && password.length < 6
      ? 'La clave debe tener al menos 6 dígitos'
      : ''
  }
  sx={{ bgcolor: 'white', borderRadius: 1 }}
/>
         <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          bgcolor: isUsernameValid ? '#d32f2f' : '#e0e0e0', // Cambiar color si está deshabilitado
          color: isUsernameValid ? 'white' : 'black',
          '&:hover': isUsernameValid ? { bgcolor: '#c62828' } : null,
          fontWeight: 'bold',
        }}
        disabled={!isUsernameValid} // Deshabilitar botón si no es válido
      >
        Inscribirse
      </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={() => navigate('/')}
          >
            Volver al Inicio
          </Button>
        </Box>
        <SnowflakeIcon sx={{ fontSize: 30, color: '#d32f2f', mt: 2 }} />
      </Box>
    </Container>
  );
}

export default SignUp;
