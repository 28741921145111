import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { db, storage } from '../firebase';
import { collection, addDoc, onSnapshot, serverTimestamp, orderBy, query, where, doc, updateDoc, getDoc, getDocs, limit, deleteDoc,setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Box, TextField, Button, Container, Typography, Paper, Avatar, IconButton, CircularProgress, Alert, AlertTitle, Modal, Badge } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Settings from '@mui/icons-material/Settings'; // Importar el ícono
import axios from 'axios';
import { runTransaction } from 'firebase/firestore';


function Chat() {
  
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [dailyMessageCount, setDailyMessageCount] = useState(0);
  const [isChatDisabled, setIsChatDisabled] = useState(false);
  const [role, setRole] = useState('normal'); 
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isSendingAudio, setIsSendingAudio] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [hasNewPrediction, setHasNewPrediction] = useState(false);
  const [usersWithPredictions, setUsersWithPredictions] = useState([]); // Usuarios que publicaron predicciones
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null);

const [coinMessage, setCoinMessage] = useState('');

  const username = localStorage.getItem('username');
  const avatarUrl = localStorage.getItem('avatarUrl');


  

  useEffect(() => {
    const publishDailySystemMessage = async () => {
      try {
        // Fecha actual
        const now = new Date();
        const year = now.getFullYear();
        const month = now.toLocaleString('default', { month: 'long' });
        const day = now.getDate();
        const weekDay = now.toLocaleDateString('default', { weekday: 'long' });
  
        // Calcular los días restantes para terminar el año
        const endOfYear = new Date(year, 11, 31); // 31 de diciembre
        const daysRemaining = Math.ceil((endOfYear - now) / (1000 * 60 * 60 * 24));
  
        const message = `📅 Hoy es ${weekDay}, ${day} de ${month} del ${year}. Quedan ${daysRemaining} días para que termine el año. ¡Aprovecha el tiempo al máximo!`;
  
        await addDoc(collection(db, 'messages'), {
          text: message,
          username: 'Sistema',
          avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/otra-151ec.appspot.com/o/avatars%2Fadministrador?alt=media&token=cd23ca64-8f38-4357-8861-8f1302e19db2',
          timestamp: serverTimestamp(),
          role: 'sistema',
        });
  
        console.log('Mensaje de fecha del sistema publicado.');
      } catch (error) {
        console.error('Error al publicar el mensaje de fecha del sistema:', error);
      }
    };
  
    const scheduleNext7AM = () => {
      const now = new Date();
      const next7AM = new Date();
      next7AM.setHours(7, 0, 0, 0); // Establecer la hora a las 7:00 AM de hoy
  
      // Si ya pasó la hora de las 7 AM hoy, programar para mañana
      if (now > next7AM) {
        next7AM.setDate(next7AM.getDate() + 1);
      }
  
      // Calcular el tiempo hasta las 7 AM
      const timeUntil7AM = next7AM - now;
  
      // Publicar el mensaje a las 7 AM y configurar el intervalo para cada 24 horas
      setTimeout(() => {
        publishDailySystemMessage();
        setInterval(publishDailySystemMessage, 24 * 60 * 60 * 1000); // Repetir cada 24 horas
      }, timeUntil7AM);
    };
  
    // Iniciar la programación
    scheduleNext7AM();
  
    // Limpiar intervalos al desmontar el componente
    return () => clearInterval(scheduleNext7AM);
  }, []);

  
  useEffect(() => {
    if (role === 'normal') {
      const interval = setInterval(() => {
        setShowModal(true);
      }, 40000); // tiempo que tarda en salir la ventana modal
      return () => clearInterval(interval);
    }
  }, [role]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleWhatsAppShare = () => {
    const message = encodeURIComponent("¡Únete a nuestro chat de Loterías! Aquí está el enlace: https://chatsrd.com/");
    const whatsappUrl = `https://api.whatsapp.com/send?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  const highlightKeyword = (text) => {
    const keywords = ['PALE', 'SUPER PALE', 'QUINIELA', 'TRIPLETA'];
    const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');
    return text.replace(regex, `
      <strong style="
        font-size: 1.4em;
        background: linear-gradient(90deg, #fbc531, #f0932b, #e84118);
        -webkit-background-clip: text;
        color: transparent;
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        padding: 2px 4px;
      ">$1</strong>
    `);
  };

  const filterMessage = (message) => {
    const bannedWords = new Set(['whatsapp', 'ws', '809', '829', '849', 'dame tu contacto', 'gmail.com', 'outlook.com']);
    let containsProfanity = false;

    const filteredMessage = message
      .split(' ')
      .map(word => {
        if (bannedWords.has(word.toLowerCase())) {
          containsProfanity = true;
          return '****';
        }
        return word;
      })
      .join(' ');

    if (containsProfanity) {
      setTimeout(async () => {
        await addDoc(collection(db, 'messages'), {
          text: 'No se permite publicar números de contactos en este chat. Favor de revisar las reglas para evitar que su cuenta se cierre',
          username: '⚠️ !AVISO!',
          avatarUrl: '',
          timestamp: serverTimestamp(),
          role: 'sistema',
        });
      }, 10000);
    }

    return filteredMessage;
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const predictionsRef = query(
      collection(db, 'predictions'),
      orderBy('timestamp', 'desc')
    );
    
    
    const unsubscribe = onSnapshot(predictionsRef, (snapshot) => {
      const loadedPredictions = snapshot.docs.map((doc) => doc.data());
      console.log(loadedPredictions); // Depurar datos
      setUsersWithPredictions(loadedPredictions);
    });
  
    return unsubscribe;
  }, []);

  useEffect(() => {
  // Función para publicar el mensaje del sistema
  const publishSystemMessage = async () => {
    try {
      await addDoc(collection(db, 'messages'), {
        text: `🔔 Recarga tu cuenta y apoya a quienes dedican su esfuerzo para compartir sus predicciones. ¡Haz clic aquí y contribuye a su labor! <a href="https://chatsrd.com/coin-history" style="color: #1e88e5; font-weight: bold;">Recargar Cuenta</a>`,
        username: 'Sistema',
        avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/otra-151ec.appspot.com/o/avatars%2Fadministrador?alt=media&token=cd23ca64-8f38-4357-8861-8f1302e19db2',
        timestamp: serverTimestamp(),
        role: 'sistema',
      });
      console.log('Mensaje del sistema publicado.');
    } catch (error) {
      console.error('Error al publicar el mensaje del sistema:', error);
    }
  };

  // Publicar el mensaje cada 48 horas (48 horas = 172800000 milisegundos)
  const interval = setInterval(() => {
    publishSystemMessage();
  }, 172800000);

  // Limpiar el intervalo al desmontar el componente
  return () => clearInterval(interval);
}, []);


  useEffect(() => {
    const userDocRef = doc(db, 'users', username);

    const checkFirstTime = async () => {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().firstTime && !userDoc.data().welcomeMessageSent) {
        await addDoc(collection(db, 'messages'), {
          text: `🎉 ¡Hola, <strong>${username}</strong>! 🎉 
          Bienvenido/a al chat por primera vez. 🎊 
          Nos alegra mucho que estés aquí. 
          🌟 ¡Únete a la conversación y sé parte de nuestra comunidad! 🌟`,
          username: 'Sistema',
          avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/otra-151ec.appspot.com/o/avatars%2Fadministrador?alt=media&token=cd23ca64-8f38-4357-8861-8f1302e19db2', // Avatar del sistema
          timestamp: serverTimestamp(),
          role: 'sistema',
          style: {
            backgroundColor: '#e0f7fa', // Azul claro suave
        color: '#00796b', // Verde azulado elegante
        fontWeight: 'bold',
        borderRadius: '12px', // Bordes ligeramente más redondeados
        padding: '12px', // Más espacio interno para mayor claridad
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', // Sombra más suave y difusa
        textAlign: 'center',
        border: '1px solid #80cbc4', // Borde suave en tono complementario
        fontSize: '1.1rem', // Tamaño de fuente ligeramente aumentado
          },
        });
    
        await updateDoc(userDocRef, {
          firstTime: false,
          welcomeMessageSent: true,
        });
      }
    };


    
    

    const fetchAvatarUrl = async () => {
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const fetchedAvatarUrl = userData.avatarUrl;
          console.log("Fetched Avatar URL:", fetchedAvatarUrl); // Depurar URL obtenida
    
          if (fetchedAvatarUrl) {
            localStorage.setItem('avatarUrl', fetchedAvatarUrl);
          } else {
            localStorage.removeItem('avatarUrl'); // Limpia si no existe
          }
        }
      } catch (error) {
        console.error("Error fetching avatar URL:", error);
      }
    };

    checkFirstTime();
    fetchAvatarUrl();


    
    updateDoc(userDocRef, { online: true });

    const unsubscribeMessages = onSnapshot(
      query(
        collection(db, 'messages'),
        orderBy('timestamp', 'desc'),
        limit(50)
      ),
      (snapshot) => {
        const loadedMessages = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        setMessages(loadedMessages.reverse());
        scrollToBottom();
      }
    );

    const unsubscribeUserDoc = onSnapshot(userDocRef, async (docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const previousRole = role;
        setRole(userData.role || 'normal');

        if (previousRole !== userData.role && ['oro', 'platino', 'moderador'].includes(userData.role)) {
          const randomNumbersDoc = await getDoc(doc(db, 'system', 'randomNumbers'));
          if (randomNumbersDoc.exists()) {
            setRandomNumbers(randomNumbersDoc.data().numbers || []);
          }
        }

        if (userData.role === 'normal') {
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const messagesQuery = query(
            collection(db, 'messages'),
            where('username', '==', username),
            where('timestamp', '>=', today)
          );

          const messagesSnapshot = await getDocs(messagesQuery);
          setDailyMessageCount(messagesSnapshot.size);

          if (messagesSnapshot.size >= 50) {
            setIsChatDisabled(true);
          }
        }
      }
    });

    const handleDisconnect = () => {
      updateDoc(userDocRef, {
        online: false,
        lastOnline: serverTimestamp(),
      });
    };

    window.addEventListener('beforeunload', handleDisconnect);

    return () => {
      unsubscribeMessages();
      unsubscribeUserDoc();
      window.removeEventListener('beforeunload', handleDisconnect);
      updateDoc(userDocRef, { online: false });
    };
  }, [username, role, avatarUrl]);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (isChatDisabled) {
      alert("Has alcanzado tu límite diario de mensajes. Debes esperar 24 horas para volver a escribir o Inscribete.");
      return;
    }

    const userDoc = await getDoc(doc(db, 'users', username));
    if (userDoc.exists() && userDoc.data().isBlocked) {
      alert("Has sido bloqueado por un moderador y no puedes enviar mensajes.");
      return;
    }

    if (newMessage.trim()) {
      const filteredMessage = filterMessage(newMessage);

      await addDoc(collection(db, 'messages'), {
        text: filteredMessage,
        username: username,
        avatarUrl: avatarUrl,
        timestamp: serverTimestamp(),
        role: role,
      });

      setNewMessage('');
      setDailyMessageCount(dailyMessageCount + 1);

      if (role === 'normal' && dailyMessageCount + 1 >= 3) {
        setIsChatDisabled(true);
        alert("Has alcanzado tu límite diario de mensajes. Debes esperar 24 horas para volver a escribir o Inscribete.");
      }

      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Error al reproducir el sonido: ", error);
        });
      }

      scrollToBottom();
    }
  };

  const handleImageUpload = async (e) => {
    if (isChatDisabled) {
      alert("Has alcanzado tu límite diario de mensajes. Debes esperar 24 horas para volver a escribir o Inscribete.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      try {
        const imageRef = ref(storage, `chatImages/${username}-${Date.now()}`);
        await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(imageRef);

        await addDoc(collection(db, 'messages'), {
          imageUrl: imageUrl,
          username: username,
          avatarUrl: avatarUrl,
          timestamp: serverTimestamp(),
          role: role
        });

        setDailyMessageCount(dailyMessageCount + 1);

        if (role === 'normal' && dailyMessageCount + 1 >= 10) {
          setIsChatDisabled(true);
          alert("Has alcanzado tu límite diario de mensajes. Debes esperar 24 horas para volver a escribir o Inscribete.");
        }

        scrollToBottom();
      } catch (error) {
        console.error("Error al subir la imagen: ", error);
      }
    }
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const options = { mimeType: 'audio/webm' }; // Grabar en formato .webm
          const recorder = new MediaRecorder(stream, options);
          setMediaRecorder(recorder);
          recorder.start();
          setIsRecording(true);
  
          const audioChunks = [];
  
          recorder.ondataavailable = event => {
            audioChunks.push(event.data);
          };
  
          recorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            const audioRef = ref(storage, `chatAudios/${username}-${Date.now()}.webm`); // Guardar como .webm inicialmente
            setIsSendingAudio(true);
  
            try {
              // Subir el archivo de audio en .webm al backend para conversión a .mp3
              await uploadBytes(audioRef, audioBlob);
              const audioUrl = await getDownloadURL(audioRef);
  
              await addDoc(collection(db, 'messages'), {
                audioUrl: audioUrl,
                username: username,
                avatarUrl: avatarUrl,
                timestamp: serverTimestamp(),
                role: role
              });
  
              setDailyMessageCount(dailyMessageCount + 1);
              if (role === 'normal' && dailyMessageCount + 1 >= 10) {
                setIsChatDisabled(true);
                alert("Has alcanzado tu límite diario de mensajes. Debes esperar 24 horas para volver a escribir o inscribirte.");
              }
  
              scrollToBottom();
            } catch (error) {
              console.error("Error al enviar la nota de voz:", error);
              alert("Hubo un error al enviar la nota de voz. Por favor, intenta nuevamente.");
            } finally {
              setIsRecording(false);
              setIsSendingAudio(false);
            }
          };
        })
        .catch(error => {
          console.error("Error al acceder al micrófono:", error);
          alert("No se pudo acceder al micrófono. Por favor, revisa los permisos.");
        });
    } else {
      alert("Tu navegador no soporta la grabación de audio.");
    }
  };
  

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();  
      setIsRecording(false);
    }
  };

  const handleAudioButtonClick = () => {
    if (isRecording) {
      stopRecording(); 
    } else {
      startRecording(); 
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await deleteDoc(doc(db, 'messages', messageId));
      alert("Mensaje eliminado correctamente.");
    } catch (error) {
      console.error("Error al eliminar el mensaje: ", error);
      alert("Hubo un error al intentar eliminar el mensaje.");
    }
  };

  const handleShareOnWhatsApp = () => {
    const message = encodeURIComponent("¡Únete a nuestro chat de Loterías! Aquí está el enlace: https://chatsrd.com/");
    const whatsappUrl = `https://api.whatsapp.com/send?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  const getMessageStyle = (msgRole) => {
  switch (msgRole) {
    case 'oro':
      return {
        backgroundColor: '#FFD700', // Dorado brillante
        color: '#000', // Texto negro
        padding: '14px', // Espaciado interno mejorado
        borderRadius: '20px', // Bordes más redondeados
        maxWidth: '85%', // Ancho máximo
        boxShadow: '0px 4px 10px rgba(255, 215, 0, 0.5)', // Sombra dorada moderna
        border: '1px solid rgba(255, 193, 7, 0.6)', // Borde sutil
      };
    case 'platino':
      return {
        backgroundColor: '#B0E0E6', // Azul platino
        color: '#000', // Texto negro
        padding: '14px',
        borderRadius: '20px',
        maxWidth: '85%',
        boxShadow: '0px 4px 10px rgba(176, 224, 230, 0.7)', // Sombra azul clara
        border: '1px solid rgba(0, 0, 0, 0.3)', // Borde negro sutil
      };
    case 'moderador':
      return {
        backgroundColor: '#228B22', // Verde oscuro
        color: '#fff', // Texto blanco
        padding: '14px',
        borderRadius: '20px',
        maxWidth: '85%',
        boxShadow: '0px 4px 10px rgba(34, 139, 34, 0.5)', // Sombra verde
        border: '1px solid rgba(255, 255, 255, 0.5)', // Borde blanco suave
      };
    case 'normal':
    default:
      return {
        backgroundColor: msgRole === username ? '#3f51b5' : '#e0e0e0', // Azul o gris según el remitente
        color: msgRole === username ? '#fff' : '#000', // Texto blanco o negro
        padding: '14px',
        borderRadius: '15px',
        maxWidth: '85%',
        boxShadow: msgRole === username
          ? '0px 4px 10px rgba(63, 81, 181, 0.5)' // Sombra azul para el usuario
          : '0px 4px 10px rgba(0, 0, 0, 0.2)', // Sombra gris para otros
        border: msgRole === username
          ? '1px solid rgba(63, 81, 181, 0.6)' // Borde azul
          : '1px solid rgba(0, 0, 0, 0.2)', // Borde gris
      };
  }
};


useEffect(() => {
  const API_URL = 'https://sorteosrd.com/api/sorteosrd-results/217XUR6ivy8A1NdI4SKcnAa9YGZRV7w3k';

  const specificLotteries = [
    'Florida Lottery 1:30 PM',
    'Florida 10:50 PM',
    'King Lottery 12:30 PM',
    'King Lottery 7:30 PM',
    'New York Lottery 3:30 PM',
    'New York Lottery 10:30 PM',
  ];

  const otherLotteries = [
    'Loto Real 12:55 PM',
    'La Suerte Dominicana 12:30PM',
    'La Suerte 6:00 PM',
    'La Primera 12:00PM',
    'La Primera 8:00 PM',
    'Quiniela Loteka 7:55 PM',
    'Quiniela LoteDom 5:55 PM',
    'Anguila 10:00 AM',
    'Anguila 1:00 PM',
    'Anguila 6:00 PM',
    'Anguila 9:00 PM',
    'Loteria Nacional 2:30 PM Gana Más',
  ];

  const allRelevantLotteries = [...specificLotteries, ...otherLotteries];

  const getCurrentDate = () => new Date().toISOString().split('T')[0];

  const processLotteryNumbers = (description, num1, num2, num3) => {
    if (specificLotteries.includes(description)) {
      return [
        parseInt(num1.toString().slice(1)),
        parseInt(num2.toString().slice(-2)),
        parseInt(num3.toString().slice(-2)),
      ];
    }
    return [parseInt(num1), parseInt(num2), parseInt(num3)];
  };

  const fetchAndProcessAciertos = async () => {
    try {
      const currentDate = getCurrentDate();

      // Obtener resultados de la API
      const apiResponse = await axios.get(API_URL);
      const apiResults = apiResponse.data
        .filter(
          (result) =>
            allRelevantLotteries.includes(result.descripcion) &&
            new Date(result.created_at).toISOString().split('T')[0] === currentDate
        )
        .map((result) => ({
          description: result.descripcion,
          lotteryNumbers: processLotteryNumbers(
            result.descripcion,
            result.num1,
            result.num2,
            result.num3
          ),
        }));

      // Obtener predicciones
      const predictionsSnapshot = await getDocs(collection(db, 'predictions'));
      const predictions = predictionsSnapshot.docs.map((doc) => ({
        username: doc.data().username,
        numbers: doc.data().numbers.split('-').map(Number),
      }));

      // Obtener claves únicas notificadas desde Firestore
      const notifiedSnapshot = await getDocs(collection(db, 'notifiedKeys'));
      const notifiedKeys = notifiedSnapshot.docs.map((doc) => doc.id);

      const nuevosAciertos = [];
      predictions.forEach((prediction) => {
        prediction.numbers.forEach((num) => {
          apiResults.forEach((result) => {
            const lotteryNumbers = result.lotteryNumbers;

            if (lotteryNumbers.includes(num)) {
              const premio =
                lotteryNumbers.indexOf(num) === 0
                  ? 'En Primera'
                  : lotteryNumbers.indexOf(num) === 1
                  ? 'En Segunda'
                  : 'En Tercera';

              const uniqueKey = `${prediction.username}-${num}-${result.description}`;
              if (!notifiedKeys.includes(uniqueKey)) {
                nuevosAciertos.push({
                  uniqueKey,
                  username: prediction.username,
                  text: `🎉 ¡Felicidades ${prediction.username}! Has acertado con el número ${num} en ${premio} en la ${result.description}. 🎊`,
                });
              }
            }
          });
        });
      });

      // Enviar mensajes y registrar notificaciones
      const batch = nuevosAciertos.map(async (acierto) => {
        await addDoc(collection(db, 'messages'), {
          text: acierto.text,
          username: 'Sistema',
          avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/otra-151ec.appspot.com/o/avatars%2Fadministrador?alt=media&token=cd23ca64-8f38-4357-8861-8f1302e19db2',
          timestamp: serverTimestamp(),
          role: 'sistema',
        });

        await setDoc(doc(db, 'notifiedKeys', acierto.uniqueKey), { timestamp: serverTimestamp() });
      });

      await Promise.all(batch);
    } catch (error) {
      console.error('Error al procesar aciertos:', error.message);
    }
  };

  const unsubscribe = onSnapshot(collection(db, 'predictions'), (snapshot) => {
    if (!snapshot.empty) {
      fetchAndProcessAciertos();
    }
  });

  return unsubscribe;
}, []);


const handlePredictionsClick = () => {
  // Solo permitir acceso a los roles 'oro' y 'platino'
  if (['oro', 'platino', 'moderador'].includes(role)) {
    setHasNewPrediction(false);
    navigate('/predictions');
  } else {
    // Mostrar alerta para roles no permitidos
    alert('🚫 Solo los miembros Oro y Platino tienen acceso a las predicciones. ¡Hazte miembro para acceder!');
  }
};

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ flexGrow: 1, position: 'relative', borderRadius: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)' }}>
        <IconButton
  component={Link}
  to="/settings"
  sx={{
    position: 'absolute',
    top: 10,
    right: 10,
    bgcolor: 'rgba(255, 255, 255, 0.1)', // Fondo translúcido
    borderRadius: '50%',
    padding: '10px',
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 0.2)', // Hover más visible
    },
    transition: 'background-color 0.3s ease',
  }}
>
  <Settings sx={{ fontSize: '30px', color: 'white' }} /> {/* Ícono blanco */}
</IconButton>

          <Typography
  variant="h4"
  gutterBottom
  sx={{
    padding: '15px',
    background: 'linear-gradient(90deg, #3f51b5, #104dd0)',
    color: 'white',
    textAlign: 'center',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    fontSize: '1.8rem',
  }}
  
>
  Bienvenido, {username}
</Typography>




{['oro', 'platino', 'moderador'].includes(role) && (
  <Box
    sx={{
      p: 2,
      textAlign: 'center',
      background: 'linear-gradient(90deg, #FFD700, #FFAA00)', // Gradiente dorado
      color: 'white',
      borderRadius: '15px',
      mb: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.0)',
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
      Números Recomendados de Hoy

      
    </Typography>
    <Typography
      variant="h4"
      sx={{
        fontWeight: 'bold',
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.)',
      }}
    >
      {randomNumbers.join(' - ')}
    </Typography>

    
  </Box>
)}

<Box
  sx={{
    textAlign: 'center',
    mb: 2,
    display: 'flex', // Alinear horizontalmente
    flexWrap: 'wrap', // Permitir que los botones se ajusten en varias filas
    justifyContent: 'center', // Centrar los botones
    gap: 2, // Espaciado uniforme entre los botones
  }}
>
  {/* Botón de Usuarios Conectados */}
  <Button
    variant="contained"
    onClick={() => navigate('/WeeklyStatistics')}
    sx={{
      bgcolor: '#3f51b5', // Azul principal
      color: 'white',
      fontWeight: 'bold',
      px: 2,
      py: 1.5,
      borderRadius: '8px',
      '&:hover': {
        bgcolor: '#2c387e',
      },
      width: {
        xs: '100%', // Ancho completo en pantallas pequeñas
        sm: 'auto', // Ancho automático en pantallas medianas y grandes
      },
    }}
  >
    Ver Estadisticas de usuarios 
  </Button>

  {/* Botón de Predicciones con notificación de campanita */}
  <motion.div
    animate={{
      opacity: [1, 0.5, 1],
    }}
    transition={{
      duration: 1.5,
      repeat: Infinity,
      ease: 'easeInOut',
    }}
    style={{
      width: '100%', // Asegura el tamaño correcto al usar motion.div
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Badge
      color="error"
      variant="dot"
      invisible={!hasNewPrediction}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Button
        variant="contained"
        onClick={handlePredictionsClick}
        sx={{
          bgcolor: '#FF5722', // Naranja moderno
          color: 'white',
          fontWeight: 'bold',
          px: 2,
          py: 1.5,
          borderRadius: '8px',
          '&:hover': {
            bgcolor: '#e64a19',
          },
          width: {
            xs: '100%', // Ancho completo en pantallas pequeñas
            sm: 'auto', // Ancho automático en pantallas medianas y grandes
          },
        }}
        endIcon={<NotificationsActiveIcon />}
      >
        Predicciones
      </Button>
    </Badge>
  </motion.div>

 
 
</Box>


          <Paper sx={{ p: 2, mb: 3, height: { xs: 'auto', md: '60vh' }, overflowY: 'auto', borderRadius: '10px', backgroundColor: '#f2f0ee' }}>
            {messages.map(({ id, data }) => (
              <motion.div 
                key={id} 
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', flexDirection: data.username === username ? 'row-reverse' : 'row' }}
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Avatar src={data.avatarUrl} sx={{ ml: data.username === username ? 2 : 0, mr: data.username !== username ? 2 : 0 }} />

                  {data.role === 'moderador' && (
                    <Typography variant="caption" sx={{ color: 'green', fontSize: '0.74rem', textAlign: 'center', fontWeight: 'bold' }}>
                      Moderador
                    </Typography>
                  )}
                  {data.role === 'oro' && (
                    <Typography variant="caption" sx={{ color: 'gold', fontSize: '0.74rem', textAlign: 'center', fontWeight: 'bold' }}>
                      ORO
                    </Typography>
                  )}
                  {data.role === 'platino' && (
                    <Typography variant="caption" sx={{ color: 'lightblue', fontSize: '0.74rem', textAlign: 'center', fontWeight: 'bold' }}>
                      Platino
                    </Typography>
                  )}
                  {data.role === 'normal' && (
                    <Typography variant="caption" sx={{ color: 'black', fontSize: '0.74rem', textAlign: 'center', fontWeight: 'bold' }}>
                      Invitado
                    </Typography>
                  )}
                </motion.div>

                <Paper 
                  sx={{ 
                    ...getMessageStyle(data.role),
                    textAlign: data.username === username ? 'right' : 'left',
                    position: 'relative',
                    marginLeft: data.username !== username ? '8px' : '0',
                    marginRight: data.username === username ? '8px' : '0'
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>{data.role === 'platino' && '⭐'} {data.username}</strong>
                  </Typography>
                  {data.text && (
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: highlightKeyword(data.text) }} />
                  )}
                  {data.imageUrl && (
                    <Box sx={{ mt: 2 }}>
                      <img src={data.imageUrl} alt="Shared content" style={{ maxWidth: '100%', height: 'auto', maxHeight: '300px', borderRadius: '8px' }} />
                    </Box>
                  )}
                  {data.audioUrl && (
                    <Box sx={{ mt: 2 }}>
                      <audio controls>
                        <source src={data.audioUrl} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    </Box>
                  )}
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    {data.timestamp && new Date(data.timestamp.seconds * 1000).toLocaleTimeString()}
                  </Typography>
                  {role === 'moderador' && (
                    <IconButton 
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => deleteMessage(id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Paper>
              </motion.div>
            ))}
            <div ref={messagesEndRef} />
          </Paper>

          {isChatDisabled && (
            <Box sx={{ mb: 3 }}>
              <Alert severity="warning">
              Has alcanzado tu límite diario de mensajes. Debes esperar 24 horas para volver a escribir o Inscribete.
              </Alert>
            </Box>
          )}

          <Box component="form" onSubmit={sendMessage} sx={{ display: 'flex', gap: 2, padding: '10px', backgroundColor: '#fff', borderRadius: '0 0 10px 10px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="newMessage"
              label="Escribe un mensaje"
              name="newMessage"
              autoComplete="off"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={isChatDisabled}
              sx={{ borderRadius: '10px', flexGrow: 1 }}
            />
            <IconButton color="primary" component="label" disabled={isChatDisabled}>
              <PhotoCamera />
              <input type="file" hidden onChange={handleImageUpload} />
            </IconButton>

            {['oro', 'platino', 'moderador'].includes(role) && (
              <>
                <IconButton 
                  color="primary" 
                  sx={{ fontSize: 37 }} 
                  onClick={handleAudioButtonClick}
                  disabled={isChatDisabled}
                >
                  {isRecording ? <StopIcon fontSize="inherit" /> : <MicIcon fontSize="inherit" />}
                </IconButton>
                {isSendingAudio && <CircularProgress size={24} />} 
              </>
            )}

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 'auto', mb: 'auto', borderRadius: '10px' }}
              disabled={isChatDisabled}
            >
              Enviar
            </Button>
          </Box>
          


          <Box sx={{ mt: 4, p: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
  <Typography variant="h6" gutterBottom>
  Predicciones actualizadas
  </Typography>
  <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto', p: 1 }}>
    {usersWithPredictions.map((user, index) => (
      <Box key={index} sx={{ textAlign: 'center' }}>
        <Avatar
  src={user.imageUrl || user.avatarUrl || 'https://chatsrd.com/estado-avatar.png'}
  sx={{
    width: 40,
    height: 40,
    mb: 1,
    border: '2px solid #4caf50',
    animation: 'pulseBorder 1.5s infinite ease-in-out', // Aplicar la animación
    '@keyframes pulseBorder': {
      '0%': { boxShadow: '0 0 0px #4caf50' },
      '50%': { boxShadow: '0 0 10px #4caf50' },
      '100%': { boxShadow: '0 0 0px #4caf50' },
    },
  }}
/>

        <Typography variant="body2" noWrap>
          {user.username}
        </Typography>
      </Box>
    ))}
  </Box>
</Box>



          {role === 'normal' && showInfoMessage && (
            <Alert 
              severity="info" 
              sx={{ mt: 2, position: 'relative' }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setShowInfoMessage(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              <AlertTitle>Información</AlertTitle>
              🟡 <strong>Hazte miembro y podrás tener acceso a los números recomendados y las mejores predicciones, el costo es de RD$1500 pesos.</strong>
            </Alert>
          )}

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button 
              variant="contained" 
              color="success" 
              startIcon={<ShareIcon />} 
              onClick={handleShareOnWhatsApp}
              sx={{ fontSize: '14px', padding: '6px 16px', borderRadius: '8px' }}
            >
              Compartir en WhatsApp
            </Button>
          </Box>

          <audio ref={audioRef} src="/sounds/notification.mp3" />
        </Box>
      </Box>

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '20px',
            maxWidth: '400px',
            margin: '100px auto',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <IconButton
            style={{ position: 'absolute', top: 10, right: 10 }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" gutterBottom>
            ¡Comparte esta página!
          </Typography>
          <Typography variant="body1">
            Ayúdanos a crecer compartiendo este chat por WhatsApp para que más personas se unan.
          </Typography>

          <Button
            variant="contained"
            color="success"
            startIcon={<ShareIcon />}
            onClick={handleWhatsAppShare}
            style={{ marginTop: '20px' }}
          >
            Compartir en WhatsApp
          </Button>
        </motion.div>
      </Modal>
    </Container>
  );
}

export default Chat;
