import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Button, Alert } from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import html2canvas from 'html2canvas';

function RecommendedNumbers() {
  const location = useLocation();
  const navigate = useNavigate();

  const { username } = location.state || {}; // Recibir `username` de Predictions
  const [vipDetails, setVipDetails] = useState(null); // Guardar los detalles de Firestore
  const [errorMessage, setErrorMessage] = useState(''); // Controlar errores

  // Consultar Firestore para obtener detalles VIP
  useEffect(() => {
    const fetchVIPDetails = async () => {
      if (!username) {
        setErrorMessage('No se recibió un usuario válido.');
        return;
      }

      try {
        const vipQuery = query(collection(db, 'vipDetails'), where('username', '==', username));
        const snapshot = await getDocs(vipQuery);

        if (!snapshot.empty) {
          const vipData = snapshot.docs[0].data();
          setVipDetails(vipData); // Guardar los datos obtenidos
        } else {
          setErrorMessage(`No se encontraron detalles VIP para el usuario: ${username}`);
        }
      } catch (error) {
        console.error('Error al cargar los detalles VIP:', error);
        setErrorMessage('Error al cargar los detalles VIP.');
      }
    };

    fetchVIPDetails();
  }, [username]);

  // Descargar los números como imagen
  const handleDownloadImage = () => {
    const element = document.getElementById('vip-numbers-display');
    if (!element) return;

    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `numeros-vip-${username}.png`;
      link.click();
    });
  };

  // Renderizar los números recomendados
  const renderNumbers = () => {
    if (!vipDetails?.Numbers || !Array.isArray(vipDetails.Numbers)) return null;

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        {vipDetails.Numbers.map((num, index) => (
          <Box
            key={index}
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              backgroundColor: '#ff6f00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#fff',
            }}
          >
            {num}
          </Box>
        ))}
      </Box>
    );
  };

  if (errorMessage) {
    return (
      <Container maxWidth="md">
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Alert severity="error">{errorMessage}</Alert>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => navigate('/predictions')}
          >
            Volver
          </Button>
        </Box>
      </Container>
    );
  }

  if (!vipDetails) {
    return (
      <Container maxWidth="md">
        <Typography variant="h5" sx={{ mt: 4, textAlign: 'center' }}>
          Cargando datos...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          mt: 4,
          padding: '20px',
          backgroundColor: '#f0f4f8',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
          Números VIP Recomendados
        </Typography>

        <Box id="vip-numbers-display">
          {renderNumbers()}
          <Typography sx={{ mt: 2, fontStyle: 'italic', color: '#616161' }}>
            Estos números son válidos por <strong>{vipDetails.Days}</strong> días.
          </Typography>
          <Typography sx={{ mt: 2, color: '#333' }}>
            <strong>Nota:</strong> {vipDetails.Nnota || 'Sin información adicional.'}
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleDownloadImage}
        >
          Descargar Números
        </Button>

        <Button
          variant="contained"
          color="success"
          sx={{ mt: 3, ml: 2 }}
          onClick={() => navigate('/chat')}
        >
          Volver al Chat
        </Button>
      </Box>
    </Container>
  );
}

export default RecommendedNumbers;
