import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { db, storage } from '../firebase';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Avatar,
  Card,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { PhotoCamera, Save, Cancel, CloudUpload } from '@mui/icons-material';
import emailjs from 'emailjs-com';


function EditProfile() {
  const [formData, setFormData] = useState({
    username: '',
    fullName: '',
    email: '',
    birthDate: '',
    city: '',
    phone: '',
    avatarUrl: '',
    role: 'normal', // Valor predeterminado
  });
  const [editCount, setEditCount] = useState(0);
  const [isEditable, setIsEditable] = useState(true);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const storedUsername = localStorage.getItem('username');
  const navigate = useNavigate();
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true); // Checkbox deshabilitado por defecto
  const [warningMessage, setWarningMessage] = useState('');
 

  useEffect(() => {
    if (!storedUsername) {
      setError('No se encontró un usuario autenticado.');
      setLoading(false);
      return;
    }
  
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', storedUsername);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
  
          // Configurar el estado del formulario
          setFormData({
            username: userData.username || storedUsername,
            fullName: userData.fullName || '',
            email: userData.email || '',
            birthDate: userData.birthDate || '',
            city: userData.city || '',
            phone: userData.phone || '',
            avatarUrl: userData.avatarUrl || '',
            role: userData.role || 'normal', // Asignar rol desde los datos o valor predeterminado
          });
  
          // Configurar habilitación del checkbox según el rol
          const rolesPermitidos = ['oro', 'moderador', 'platino'];
          if (rolesPermitidos.includes(userData.role)) {
            setIsCheckboxDisabled(false); // Habilitar checkbox
          } else {
            setIsCheckboxDisabled(true); // Deshabilitar checkbox
          }
        } else {
          setError('No se encontraron datos para este usuario.');
        }
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
        setError('Hubo un problema al cargar los datos del usuario.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, [storedUsername]);
  

  const validateField = (name, value) => {
    let error = '';
    if (!value.trim()) {
      error = 'Este campo es obligatorio.';
    } else {
      switch (name) {
        case 'email':
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            error = 'Correo electrónico no válido.';
          }
          break;
        case 'phone':
          if (!/^\d{10}$/.test(value)) {
            error = 'El número de teléfono debe tener 10 dígitos.';
          }
          break;
        case 'birthDate':
          if (new Date(value) > new Date()) {
            error = 'La fecha de nacimiento no puede ser futura.';
          }
          break;
        case 'city':
          if (value.trim().length < 3) {
            error = 'La ciudad debe tener al menos 3 caracteres.';
          }
          break;
        default:
          break;
      }
    }
    return error;
  };

   // Definir handleCheckboxChange para el checkbox
   const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setIsModalOpen(true); // Abrir modal al marcar el checkbox
    }
  };


  const handleAcceptRequest = async () => {
    try {
      // Configura los parámetros de la plantilla
      const templateParams = {
        to_email: 'sorteosrd@gmail.com', // Correo que recibe la solicitud
        from_name: formData.username, // Nombre del usuario que envía
        message: `El usuario ${formData.username} ha solicitado ganar dinero con sus números recomendados.`,
      };
  
      // Envía el correo con EmailJS
      await emailjs.send(
        'service_zf7ax3g', // ID de servicio de EmailJS (proporcionado por su panel)
        'template_ewkstkv', // ID de la plantilla creada
        templateParams,
        'ZHgXgKbdCEry2epjM' // ID del usuario (puedes obtenerlo desde tu panel de EmailJS)
      );
  
      alert('Tu solicitud está siendo revisada. En breve recibirás noticias.');
    } catch (error) {
      console.error('Error al enviar el correo:', error.message);
      alert('Hubo un error al enviar la solicitud. Inténtalo más tarde.');
    } finally {
      setIsModalOpen(false); // Cierra la ventana modal
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    const fieldError = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: fieldError }));
  };

  const handleAvatarUpload = async () => {
    if (!avatarFile) return;

   

    setAvatarLoading(true);
    try {
      const avatarRef = ref(storage, `avatars/${storedUsername}`);
      const uploadTask = uploadBytesResumable(avatarRef, avatarFile);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Error al subir el avatar:', error);
          setAvatarLoading(false);
        },
        async () => {
          const avatarUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData((prev) => ({ ...prev, avatarUrl }));
          const userDocRef = doc(db, 'users', storedUsername);
          await updateDoc(userDocRef, { avatarUrl });
          setAvatarLoading(false);
          alert('Avatar actualizado exitosamente.');
        }
      );
    } catch (error) {
      console.error('Error al subir el avatar:', error);
      setAvatarLoading(false);
    }
  };


  const handleCloseModal = () => {
    setIsModalOpen(false); // Cerrar el modal al cancelar
  };

  const handleSave = async () => {
    if (editCount >= 2) {
      alert('Ya no puedes editar estos datos.');
      return;
    }

    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (field !== 'avatarUrl') {
        const error = validateField(field, formData[field]);
        if (error) newErrors[field] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      const userDocRef = doc(db, 'users', storedUsername);
      await updateDoc(userDocRef, {
        ...formData,
        editCount: editCount + 1,
      });
      setEditCount((prev) => prev + 1);
      setIsEditable(editCount + 1 < 2);
      alert('Perfil actualizado exitosamente.');
      navigate('/settings');
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      setError('Hubo un error al guardar los cambios. Inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Box mt={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" color="primary" gutterBottom>
            Editar Perfil
          </Typography>



          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Avatar
              src={formData.avatarUrl}
              alt="Avatar"
              sx={{ width: 120, height: 120, marginBottom: 2 }}
            />

            <Button
              variant="contained"
              startIcon={<PhotoCamera />}
              component="label"
              color="primary"
              disabled={avatarLoading}
            >
              Cambiar Avatar
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => setAvatarFile(e.target.files[0])}
              />
            </Button>

            {avatarFile && (
              <Button
                variant="outlined"
                startIcon={<CloudUpload />}
                onClick={handleAvatarUpload}
                color="secondary"
                disabled={avatarLoading}
              >
                Subir Avatar
              </Button>
            )}

            {avatarLoading && <CircularProgress />}
          </Box>

          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre de Usuario"
                  fullWidth
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre Completo"
                  fullWidth
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  disabled={!isEditable}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Correo Electrónico"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={!isEditable}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Fecha de Nacimiento"
                  type="date"
                  fullWidth
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                  disabled={!isEditable}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.birthDate}
                  helperText={errors.birthDate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ciudad"
                  fullWidth
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  disabled={!isEditable}
                  error={!!errors.city}
                  helperText={errors.city}
                 
                />
                
              </Grid>
            </Grid>
          </Box>
          {/* Checkbox */}
 <Box mt={4}>
 <FormControlLabel
  control={
    <Checkbox
      disabled={isCheckboxDisabled} // Deshabilitar si el rol no está permitido
      onChange={handleCheckboxChange}
    />
  }
  label="Solicitar ganar dinero con los números recomendados"
/>
          </Box>

        </CardContent>
        <CardActions>
          
          <Button
            variant="contained"
            startIcon={<Save />}
            onClick={handleSave}
            color="primary"
          >
            Guardar Cambios
          </Button>
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => navigate('/settings')}
            color="error"
          >
            Cancelar
          </Button>
 

           {/* Modal */}
           <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <DialogTitle>Ganar Dinero con tus Números</DialogTitle>
            <DialogContent>
              <Typography>
                Ahora puedes ganar dinero con los números recomendados que compartes. Las personas
                podrán enviarte monedas para obtener acceso a ellos. ¿Deseas enviar la solicitud?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAcceptRequest} color="primary" variant="contained">
                Aceptar
              </Button>
              <Button onClick={handleCloseModal} color="secondary" variant="outlined">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
          
        </CardActions>
        
      </Card>

      
    </Container>
  );
}

export default EditProfile;
