// MaintenancePage.js
import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const MaintenancePage = () => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent("¡Hola! Estoy interesado en obtener más información sobre cómo hacerme miembro.");
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+18298415339&text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Mantenimiento en Proceso
      </Typography>
      <Typography variant="body1" paragraph>
        Actualmente, el chat está en mantenimiento para usuarios de tipo "normal". Para acceder a funciones adicionales, puedes hacerte miembro.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<WhatsAppIcon />}
        onClick={handleWhatsAppClick}
        style={{ marginTop: '20px' }}
      >
        Contactar por WhatsApp
      </Button>
    </Container>
  );
};

export default MaintenancePage;
