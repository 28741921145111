import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

function BuyCoinsDialog({ open, onClose, username }) {
  const [selectedBank, setSelectedBank] = useState('');
  const detailsRef = useRef(null);

  const bankDetails = {
    banreservas: {
      name: 'Banreservas',
      accountNumber: '9600560725',
      holder: 'Marcos Valerio',
    },
    popular: {
      name: 'Banco Popular',
      accountNumber: '003-2345559933',
      holder: 'Marcos Valerio',
    },
    caribe: {
      name: 'Caribe Express',
      accountNumber: 'Envio',
      holder: 'Marcos Anonio Valerio Delgado',
    },
    western: {
      name: 'Wester Union',
      accountNumber: 'Envio',
      holder: 'Ivania Maria Jaquez',
    },
  };

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  const handleGenerateImage = () => {
    if (detailsRef.current) {
      html2canvas(detailsRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.download = 'bank-details.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Comprar Monedas</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Para comprar monedas, debes hacer un depósito a una de las siguientes cuentas:
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="bank-account-label">Selecciona una cuenta</InputLabel>
          <Select
            labelId="bank-account-label"
            value={selectedBank}
            onChange={handleBankChange}
          >
            <MenuItem value="banreservas">Banreservas</MenuItem>
            <MenuItem value="popular">Banco Popular</MenuItem>
            <MenuItem value="caribe">Caribe Express</MenuItem>
            <MenuItem value="western">Western Union</MenuItem>
          </Select>
        </FormControl>

        {selectedBank && (
          <Box
            ref={detailsRef}
            sx={{ mt: 2, p: 2, backgroundColor: '#f1f1f1', borderRadius: '8px' }}
          >
            <Typography variant="body1">
              <strong>Banco:</strong> {bankDetails[selectedBank].name}
            </Typography>
            <Typography variant="body1">
              <strong>Número de cuenta:</strong> {bankDetails[selectedBank].accountNumber}
            </Typography>
            <Typography variant="body1">
              <strong>Titular:</strong> {bankDetails[selectedBank].holder}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <strong>Usuario:</strong> {username}
            </Typography>
          </Box>
        )}

        <Typography sx={{ mt: 2 }}>
          Al realizar el depósito, asegúrate de incluir una nota con tu nombre de usuario:{' '}
          <strong style={{ color: '#1976d2' }}>{username}</strong>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Recuerda que <strong>1 moneda equivale a 100 pesos dominicanos</strong>. Establece el monto a depositar acorde a la cantidad de monedas que deseas comprar.
        </Typography>
      </DialogContent>

      <DialogActions>
        {selectedBank && (
          <Button onClick={handleGenerateImage} color="secondary">
            Generar Imagen
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BuyCoinsDialog;
