import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import html2canvas from 'html2canvas';
import { db } from '../firebase';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChatIcon from '@mui/icons-material/Chat';


function PredictionsWithVIPAndDownload() {
  const [allPredictions, setAllPredictions] = useState([]);
  const [numeros, setNumeros] = useState('');
  const [categories, setCategories] = useState({
    quiniela: false,
    pale: false,
    tripleta: false,
  });
  
  const [vipInfo, setVipInfo] = useState({ numbers: [], days: 0, cost: 0, note: '' });
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [publishSuccess, setPublishSuccess] = useState('');
  const [publishError, setPublishError] = useState('');
  const [isVIPConfirmDialogOpen, setIsVIPConfirmDialogOpen] = useState(false);
  const [isVIPDetailsDialogOpen, setIsVIPDetailsDialogOpen] = useState(false);
  const currentUser = localStorage.getItem('username');
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true); // Deshabilitado por defecto
  const [userRole, setUserRole] = useState(''); // Estado para el rol del usuario

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const currentUser = localStorage.getItem('username'); // Get logged-in user
        const userDocRef = doc(db, 'users', currentUser); // Reference to Firestore document
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const role = userDoc.data().role || 'normal'; // Default to 'normal' if no role
          setUserRole(role);
  
          // Enable inputs and button only for allowed roles
          if (['oro', 'moderador', 'platino'].includes(role.toLowerCase())) {
            setIsDisabled(false); // Allow interaction for these roles
          }
        } else {
          console.error('Usuario no encontrado.');
        }
      } catch (error) {
        console.error('Error al obtener el rol del usuario:', error);
      }
    };
  
    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const predictionsQuery = query(collection(db, 'predictions'));
        const snapshot = await getDocs(predictionsQuery);
        const predictionsData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const predictionData = docSnapshot.data();
            const userDocRef = doc(db, 'users', predictionData.username);
            const userDoc = await getDoc(userDocRef);

            const isNumerologo = userDoc.exists() ? userDoc.data().numerologo || false : false;

            return {
              id: docSnapshot.id,
              ...predictionData,
              numerologo: isNumerologo,
            };
          })
        );
        setAllPredictions(predictionsData);
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
    };

    fetchPredictions();
  }, []);

  const handleCategoryChange = (event) => {
    setCategories({
      ...categories,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePublishPrediction = async () => {
  
    const selectedCategories = Object.keys(categories).filter((key) => categories[key]);

    if (!numeros?.trim() || selectedCategories.length === 0) {
      setPublishError('Por favor, ingresa los números y selecciona al menos una categoría.');
      setPublishSuccess('');
      return;
    }

    try {
      // Verificar si ya existe una predicción con los mismos números y categorías seleccionadas
      const existingPredictionsQuery = query(
        collection(db, 'predictions'),
        where('username', '==', currentUser), // Filtrar por usuario actual
        where('numbers', '==', numeros) // Filtrar por números
      );

      const existingPredictionsSnapshot = await getDocs(existingPredictionsQuery);

      if (!existingPredictionsSnapshot.empty) {
        const duplicateCategoryFound = existingPredictionsSnapshot.docs.some((doc) => {
          const data = doc.data();
          // Comparar categorías existentes con las seleccionadas
          return (
            data.categories &&
            selectedCategories.every((category) => data.categories.includes(category))
          );
        });
  
        if (duplicateCategoryFound) {
          setPublishError('Ya has publicado esta predicción con las mismas categorías.');
          setPublishSuccess('');
          return;
        }
    }

    // Publicar la nueva predicción con todas las categorías seleccionadas
    await addDoc(collection(db, 'predictions'), {
      username: currentUser || 'Anónimo',
      categories: selectedCategories, // Almacenar todas las categorías seleccionadas como un array
      numbers: numeros,
      timestamp: Timestamp.fromDate(new Date()),
    });
  
    setPublishSuccess('Predicción publicada con éxito.');
    setPublishError('');
    setNumeros('');
    setCategories({ quiniela: false, pale: false, tripleta: false });

    // Deshabilitar inputs hasta el próximo día a las 2:00 am
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(2, 0, 0, 0); // Configurar a las 2:00 am del próximo día

    const timeToDisable = tomorrow.getTime() - now.getTime();

    setIsDisabled(true); // Deshabilitar inputs inmediatamente

    setTimeout(() => {
      setIsDisabled(false); // Habilitar inputs a las 2:00 am del siguiente día
    }, timeToDisable);

    // Actualizar lista de predicciones
    const predictionsQuery = query(collection(db, 'predictions'));
    const snapshot = await getDocs(predictionsQuery);
    const predictionsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAllPredictions(predictionsData);
  } catch (error) {
    console.error('Error publishing prediction:', error);
    setPublishError('Error al publicar la predicción.');
    setPublishSuccess('');
  }
};


  const handleVIPAction = async (entry) => {
    try {
      const userDocRef = doc(db, 'users', currentUser);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const vipAccess = userDoc.data().vipAccess || {};
        const accessExpiration = vipAccess[entry.username];
        const today = new Date();
  
        // Si el acceso VIP es válido
        if (accessExpiration && new Date(accessExpiration.toDate()) > today) {
          const vipQuery = query(collection(db, 'vipDetails'), where('username', '==', entry.username));
          const snapshot = await getDocs(vipQuery);
  
          if (!snapshot.empty) {
            const vipData = snapshot.docs[0].data();
            setVipInfo({
              numbers: Array.isArray(vipData.Numbers) ? vipData.Numbers : [],
              days: vipData.Days || 0,
              cost: vipData.Cost || 0,
              note: vipData.Nnota || 'Sin información disponible',
            });
            setIsVIPDetailsDialogOpen(true); // Mostrar directamente detalles VIP
            return;
          }
        }
      }
  
      // Si no hay acceso VIP válido, pedir confirmación
      const vipQuery = query(collection(db, 'vipDetails'), where('username', '==', entry.username));
      const snapshot = await getDocs(vipQuery);
  
      if (!snapshot.empty) {
        const vipData = snapshot.docs[0].data();
        setVipInfo({
          numbers: Array.isArray(vipData.Numbers) ? vipData.Numbers : [],
          days: vipData.Days || 0,
          cost: vipData.Cost || 0,
          note: vipData.Nnota || 'Sin información disponible',
        });
        setSelectedEntry(entry);
        setIsVIPConfirmDialogOpen(true); // Pedir confirmación
      }
    } catch (error) {
      console.error('Error fetching VIP details:', error);
    }
  };
  

  const handleConfirmVIP = async () => {
    try {
      const userDocRef = doc(db, 'users', currentUser);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userCoins = userDoc.data().monOro || 0;
  
        if (userCoins >= vipInfo.cost) {
          // Deduce monedas del usuario
          await updateDoc(userDocRef, { monOro: userCoins - vipInfo.cost });
  
          // Calcular la nueva fecha de expiración
          const expirationDate = new Date();
          expirationDate.setDate(new Date().getDate() + vipInfo.days);
  
          // Actualizar acceso VIP en el usuario
          await updateDoc(userDocRef, {
            [`vipAccess.${selectedEntry.username}`]: expirationDate,
          });
  
          // Obtener referencia al creador del VIP
          const creatorDocRef = doc(db, 'users', selectedEntry.username);
          const creatorDoc = await getDoc(creatorDocRef);
  
          if (creatorDoc.exists()) {
            const creatorCoins = creatorDoc.data().monOro || 0;
  
            // Actualizar las monedas del creador
            await updateDoc(creatorDocRef, {
              monOro: creatorCoins + vipInfo.cost,
            });
  
            // Registrar transacción en el historial del creador
            await addDoc(collection(db, `users/${selectedEntry.username}/CoinHistory`), {
              timestamp: Timestamp.fromDate(new Date()),
              type: 'VIP Earning',
              amount: vipInfo.cost,
              relatedUser: currentUser,
              description: `Recibiste ${vipInfo.cost} monedas por acceso VIP comprado por ${currentUser}.`,
            });
          }
  
          // Registrar transacción en el historial del usuario que paga
          await addDoc(collection(db, `users/${currentUser}/CoinHistory`), {
            timestamp: Timestamp.fromDate(new Date()),
            type: 'VIP Payment',
            amount: -vipInfo.cost,
            relatedUser: selectedEntry.username,
            description: `Pagaste ${vipInfo.cost} monedas por acceso VIP a ${selectedEntry.username}.`,
          });
  
          // Mostrar detalles VIP después de la confirmación
          setIsVIPConfirmDialogOpen(false);
          setIsVIPDetailsDialogOpen(true);
        } else {
          alert('No tienes suficientes monedas.');
        }
      }
    } catch (error) {
      console.error('Error processing VIP access:', error);
    }
  };

  const handleDownloadAsImage = (id) => {
    const element = document.getElementById(`prediction-${id}`);
    if (!element) return;

    const downloadButton = element.querySelector('.download-btn');
    downloadButton.style.display = 'none';

    html2canvas(element)
      .then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `prediccion-${id}.png`;
        link.click();

        downloadButton.style.display = 'inline-block';
      })
      .catch((error) => {
        console.error('Error generating image:', error);
        downloadButton.style.display = 'inline-block';
      });
  };

  const renderNumbersInCircles = (numbersString) => {
    const numbersArray = numbersString.split('-');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        {numbersArray.map((num, index) => (
          <Box
            key={index}
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              backgroundColor: '#ff6f00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#fff',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          >
            {num}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      {/* Publicar Predicciones */}
      <Box
  sx={{
    mt: 4,
    p: 4,
    backgroundColor: '#f0f4f8',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  }}
>
  {/* Título */}
  <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#1976d2' }}>
    Publicar Predicciones
  </Typography>
  <Typography
  variant="body1"
  gutterBottom
  sx={{
    textAlign: 'center',
    color: '#616161', // Color ligeramente más oscuro para mejor legibilidad
    fontSize: '18px', // Aumentamos el tamaño del texto
    fontWeight: '500', // Le damos un peso medio para resaltar
    letterSpacing: '0.5px', // Añadimos espaciado entre letras
    lineHeight: '1.6', // Ajustamos el espaciado entre líneas
    marginTop: '10px', // Margen superior para separar del contenido previo
  }}
>
  Ingrese los números recomendados y seleccione las categorías.
</Typography>
  {/* Mensajes de Error/Éxito */}
  {publishError && <Alert severity="error" sx={{ mt: 2 }}>{publishError}</Alert>}
  {publishSuccess && <Alert severity="success" sx={{ mt: 2 }}>{publishSuccess}</Alert>}

{/* Campo de Entrada de Números */}
<Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
  <TextField
    value={numeros}
    onChange={(e) => {
      let value = e.target.value.replace(/[^0-9]/g, ''); // Permitir solo números
      if (value.length <= 10) { // Limitar la entrada total a 10 dígitos
        // Formatear como números de dos dígitos separados por guiones
        const formattedValue = value
          .match(/.{1,2}/g) // Agrupar cada dos dígitos
          ?.join('-') || ''; // Insertar guiones entre los grupos
        setNumeros(formattedValue);
      }
    }}
    error={numeros.split('-').length !== 5 || numeros.includes('--')} // Error si no son exactamente 5 números de 2 dígitos
    helperText={
      numeros.split('-').length !== 5 || numeros.includes('--')
        ? 'Debe ingresar exactamente 5 números de dos dígitos'
        : ''
    }
    disabled={isDisabled} // Deshabilitar dinámicamente
    placeholder="Ingrese 5 números de dos dígitos"
    fullWidth
    sx={{
      maxWidth: '400px',
      textAlign: 'center',
      backgroundColor: isDisabled ? '#e0e0e0' : '#fff',
      borderRadius: '8px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    }}
  />
</Box>



<FormGroup sx={{ mt: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
  {Object.keys(categories).map((category) => (
    <FormControlLabel
      key={category}
      control={
        <Checkbox
          checked={categories[category]}
          onChange={handleCategoryChange}
          name={category}
          disabled={isDisabled} // Deshabilitar dinámicamente
        />
      }
      label={category.charAt(0).toUpperCase() + category.slice(1)} // Capitalizar nombre
      sx={{ mx: 2 }}
    />
  ))}
</FormGroup>

  {/* Botón Publicar */}
  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
  <Button
    variant="contained"
    onClick={handlePublishPrediction}
    sx={{
      px: 5,
      py: 1.5,
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#4caf50',
      color: '#fff',
      borderRadius: '8px',
      '&:hover': { backgroundColor: '#388e3c' },
    }}
  >
    Publicar Predicción
  </Button>
</Box>
</Box>


      {/* Predicciones Publicadas */}
      <Box
        sx={{
          mt: 4,
          p: 4,
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', color: '#1976d2' }}>
          Predicciones Publicadas
        </Typography>

        {allPredictions.length > 0 ? (
          allPredictions.map((entry) => (
            <Box
  key={entry.id}
  id={`prediction-${entry.id}`}
  sx={{
    backgroundColor: '#e3f2fd',
    borderRadius: '12px',
    padding: '20px',
    marginBottom: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center', // Centra todo el contenido dentro de cada predicción
  }}
>
  {/* Nombre de usuario */}
  <Typography
  variant="subtitle1"
  sx={{
    fontWeight: 'bold',
    color: '#1a237e',
    fontSize: '18px',
    textAlign: 'center',
    textTransform: 'uppercase', // Convierte el texto a mayúsculas
  }}
>
  {entry.username}
</Typography>

  {/* Fecha de publicación */}
  <Typography
    variant="body2"
    sx={{
      fontWeight: 'bold',
      mt: 1,
      mb: 2, // Margen inferior para separar de los botones
      color: '#757575',
      textAlign: 'center',
    }}
  >
    Publicado el:{' '}
    {new Date(entry.timestamp.toDate()).toLocaleDateString('es-ES', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })}{' '}
    a las{' '}
    {new Date(entry.timestamp.toDate()).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
    })}
  </Typography>

  {/* Números Renderizados como Círculos */}
  {renderNumbersInCircles(entry.numbers)}

  {/* Botones centrados */}
  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
    {entry.numerologo && (
      <Button
        variant="contained"
        color="warning"
        onClick={() => handleVIPAction(entry)}
        sx={{
          fontSize: '12px',
          fontWeight: 'bold',
          px: 2,
          py: 1,
          borderRadius: '6px',
        }}
      >
        VIP
      </Button>
    )}

    <Button
      variant="contained"
      color="secondary"
      className="download-btn"
      onClick={() => handleDownloadAsImage(entry.id)}
      sx={{
        fontSize: '12px',
        fontWeight: 'bold',
        px: 2,
        py: 1,
        borderRadius: '6px',
        '&:hover': { backgroundColor: '#1976d2' },
      }}
    >
      Descargar
    </Button>
  </Box>
</Box>

          ))
        ) : (
          <Typography>No hay predicciones publicadas.</Typography>
        )}
      </Box>

     {/* Modal de Confirmación VIP */}
<Dialog
  open={isVIPConfirmDialogOpen}
  onClose={() => setIsVIPConfirmDialogOpen(false)}
  sx={{
    '& .MuiPaper-root': {
      borderRadius: '16px',
      padding: '20px',
      maxWidth: '500px',
    },
  }}
>
  <DialogTitle
    sx={{
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#1976d2',
      textAlign: 'center',
    }}
  >
    Confirmar Acceso VIP
  </DialogTitle>
  <DialogContent
    sx={{
      textAlign: 'center',
      fontSize: '16px',
      color: '#555',
    }}
  >
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#1a237e',
        mb: 1,
      }}
    >
      Usuario: {selectedEntry?.username}
    </Typography>

    {selectedEntry?.username === currentUser ? (
  // Mostrar directamente los números publicados por el usuario actual
  <>
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#1a237e',
        mb: 2,
        textAlign: 'center',
      }}
    >
      Estos son tus números publicados:
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '10px',
        mb: 3,
      }}
    >
      {vipInfo.numbers.map((num, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: '#e3f2fd',
            color: '#1976d2',
            fontWeight: 'bold',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {num}
        </Box>
      ))}
    </Box>
    <Typography
      sx={{
        mt: 2,
        fontSize: '16px',
        color: '#616161',
        lineHeight: '1.6',
        textAlign: 'center',
      }}
    >
      {vipInfo.note || 'Sin información adicional.'}
    </Typography>
  </>
) : (
  // Mostrar la interfaz para comprar acceso a los números de otro usuario
  <>
    <Typography sx={{ mb: 2 }}>
      <strong>Monedas Requeridas:</strong> {vipInfo.cost}
    </Typography>
    <Typography>
      <strong>Días de Validez:</strong> {vipInfo.days}
    </Typography>
    <Typography sx={{ mt: 2, color: '#616161' }}>
      Si aceptas pagar, tendrás acceso a los números VIP por el tiempo establecido.
    </Typography>
  </>
)}
  </DialogContent>
  <DialogActions
    sx={{
      justifyContent: 'space-evenly',
      paddingBottom: '16px',
    }}
  >
    {selectedEntry?.username !== currentUser && (
      <Button
        onClick={handleConfirmVIP}
        variant="contained"
        sx={{
          backgroundColor: '#4caf50',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '14px',
          px: 4,
          py: 1,
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#388e3c',
          },
        }}
      >
        Confirmar
      </Button>
    )}
    <Button
      onClick={() => setIsVIPConfirmDialogOpen(false)}
      variant="outlined"
      sx={{
        borderColor: '#f44336',
        color: '#f44336',
        fontWeight: 'bold',
        fontSize: '14px',
        px: 4,
        py: 1,
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#ffebee',
        },
      }}
    >
      Cancelar
    </Button>
  </DialogActions>
</Dialog>


    {/* Modal de Detalles VIP */}
<Dialog
  open={isVIPDetailsDialogOpen}
  onClose={() => setIsVIPDetailsDialogOpen(false)}
  sx={{
    '& .MuiPaper-root': {
      borderRadius: '16px',
      padding: '20px',
      maxWidth: '500px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
    },
  }}
>
  <DialogTitle
    sx={{
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#1976d2',
      textAlign: 'center',
      borderBottom: '2px solid #e3f2fd',
      paddingBottom: '10px',
      marginBottom: '10px',
    }}
  >
    Detalles VIP
  </DialogTitle>
  <DialogContent
    sx={{
      textAlign: 'center',
      fontSize: '16px',
      color: '#555',
    }}
  >
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#1976d2',
        mb: 2,
      }}
    >
      Números VIP:
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '10px',
        mb: 3,
      }}
    >
      {vipInfo.numbers.map((num, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: '#e3f2fd',
            color: '#1976d2',
            fontWeight: 'bold',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {num}
        </Box>
      ))}
    </Box>
    <Typography
      sx={{
        mt: 2,
        fontSize: '16px',
        color: '#616161',
        lineHeight: '1.6',
      }}
    >
      {vipInfo.note}
    </Typography>
  </DialogContent>
  <DialogActions
    sx={{
      justifyContent: 'center',
      paddingTop: '16px',
      borderTop: '2px solid #e3f2fd',
      marginTop: '10px',
    }}
  >
    <Button
      onClick={() => setIsVIPDetailsDialogOpen(false)}
      variant="contained"
      sx={{
        backgroundColor: '#4caf50',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '14px',
        px: 4,
        py: 1.2,
        borderRadius: '12px',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      }}
    >
      Cerrar
    </Button>
  </DialogActions>
</Dialog>


      <Box
    sx={{
      mt: 5,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 2,
      p: 3,
      backgroundColor: '#f9f9f9',
      borderRadius: '12px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    }}
  >
    {/* Botón Contactar por WhatsApp */}
    <Button
      variant="outlined"
      startIcon={<WhatsAppIcon />}
      onClick={() => window.open('https://wa.me/18298415339', '_blank')}
      sx={{
        borderColor: '#25D366',
        color: '#25D366',
        fontWeight: 'bold',
        px: 4,
        py: 1.5,
        borderRadius: '8px',
        fontSize: '16px',
        '&:hover': {
          borderColor: '#1ebe57',
          backgroundColor: '#e7fce7',
        },
      }}
    >
      Contactar por WhatsApp
    </Button>

    {/* Botón Volver al Chat */}
    <Button
      variant="contained"
      color="primary"
      startIcon={<ChatIcon />}
      onClick={() => navigate('/chat')}
      sx={{
        px: 4,
        py: 1.5,
        fontWeight: 'bold',
        fontSize: '16px',
        borderRadius: '8px',
      }}
    >
      Volver al Chat
    </Button>

    {/* Botón Estadísticas */}
    <Button
      variant="contained"
      color="success"
      startIcon={<BarChartIcon />}
      onClick={() => navigate('/WeeklyStatistics')}
      sx={{
        px: 4,
        py: 1.5,
        fontWeight: 'bold',
        fontSize: '16px',
        borderRadius: '8px',
        backgroundColor: '#4caf50',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      }}
    >
      Estadísticas
    </Button>
  </Box>
    </Container>
    
  );
}
export default PredictionsWithVIPAndDownload;
