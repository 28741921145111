import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

import { db } from '../firebase';
import {
  Container,
  Typography,
  Box,
  Avatar,
  Button,
  CircularProgress,
  TextField,
  Modal,
  Alert,
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

function Settings() {
  const [role, setRole] = useState('normal');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [monOro, setMonOro] = useState(0);
  const [numerologo, setNumerologo] = useState(false);
  const [numbers, setNumbers] = useState('');
  const [days, setDays] = useState('');
  const [cost, setCost] = useState('');
  const [nnota, setNnota] = useState('');
  const [createdAt, setCreatedAt] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const username = localStorage.getItem('username');
  const navigate = useNavigate();
  const [isBlocked, setIsBlocked] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);

  const handleNumberInputChange = (e) => {
    let input = e.target.value.replace(/[^0-9]/g, ""); // Elimina cualquier carácter no numérico
    const formattedInput = input
      .match(/.{1,2}/g) // Agrupa los números en pares de dos
      ?.join("-") // Une los pares con un guión
      .slice(0, 8); // Limita a un máximo de 11 caracteres (3 pares y 2 guiones)
  
    setNumbers(formattedInput || ""); // Actualiza el estado con el formato
  };
  
  const generateReferralLink = () => {
    const baseUrl = window.location.origin; // Obtiene el dominio actual (por ejemplo, https://tusitio.com)
    return `${baseUrl}/signup?ref=${username}`;
  };


  const handleEditProfile1 = () => {
    navigate('/EditProfile');
  };

  useEffect(() => {

    const checkExpiration = () => {
  if (expirationDate && new Date() > expirationDate) {
    setIsBlocked(false);
    setExpirationDate(null);
  }
};
  
    checkExpiration();
    const fetchUserData = async () => {
      if (!username) {
        console.error('No se encontró el nombre de usuario. Redirigiendo al login...');
        navigate('/');
        return;
      }

      try {
        const userDocRef = doc(db, 'users', username);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const data = userDocSnap.data();
          setRole(data.role || 'normal');
          setAvatarUrl(data.avatarUrl || '');
          setMonOro(data.monOro || 0);
          setNumerologo(data.numerologo || false);
          if (data.createdAt) {
            setCreatedAt(new Date(data.createdAt.seconds * 1000));
          }
          if (data.expiryDate) {
            setExpiryDate(new Date(data.expiryDate.seconds * 1000));
          }

          if (data.expirationDate) {
            const expiry = new Date(data.expirationDate);
            setExpirationDate(expiry);
    
            // Si la fecha ya pasó, limpiar el valor en Firebase
            if (expiry && new Date() > expiry) {
              await updateDoc(userDocRef, { expirationDate: null });
              setExpirationDate(null);
            }
          }
          
          
        }
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
      } finally {
        setLoading(false);
      }
    };
    checkExpiration();
    fetchUserData();
  }, [username, navigate],  [expirationDate]);


 




  const handleSavePublication = async () => {
    setErrorMessage('');

    if (!numbers || !days || !cost || !nnota) {
      setErrorMessage('Por favor, completa todos los campos antes de guardar.');
      return;
    }

    const numbersArray = numbers.split('-').map((num) => num.trim());
    if (numbersArray.length !== 3) {
      setErrorMessage('Debes ingresar exactamente 3 números.');
      return;
    }

    

    const parsedDays = parseInt(days, 5); // maximo 4 dias para jugar los numeros
    const parsedCost = parseInt(cost, 6); // maximo 500 pesos por jugar, luego que suba de categoria

    const validateInput = () => {
      // Reseteamos el mensaje de error antes de realizar la validación
      setErrorMessage('');
    
      if (isNaN(parsedDays) || parsedDays <= 0 || parsedDays > 4) {
        setErrorMessage(
          <Alert
            severity="warning"
            sx={{
              mt: 2,
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#ff5722', // Color naranja para destacar
              backgroundColor: '#fffbe6', // Fondo claro
              border: '1px solid #ffd54f', // Borde con color amarillo
              borderRadius: '8px', // Bordes redondeados
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Sombra ligera
              padding: '10px',
            }}
          >
            Las personas deben jugar los números máximo 4 días.
          </Alert>
        );
        return;
      }
    
      if (isNaN(parsedCost) || parsedCost <= 0) {
        setErrorMessage(
          <Alert
            severity="error"
            sx={{
              mt: 2,
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#d32f2f', // Rojo oscuro para errores
              backgroundColor: '#ffebee', // Fondo claro rojo
              border: '1px solid #e57373', // Borde rojo claro
              borderRadius: '8px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              padding: '10px',
            }}
          >
            Debe establecer un valor mínimo de 1 moneda.
          </Alert>
        );
        return;
      }
    
      if (isNaN(parsedCost) || parsedCost >= 6) {
        setErrorMessage(
          <Alert
            severity="warning"
            sx={{
              mt: 2,
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#ff9800', // Naranja
              backgroundColor: '#fff3e0', // Fondo naranja claro
              border: '1px solid #ffcc80',
              borderRadius: '8px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              padding: '10px',
            }}
          >
            Como máximo debe establecer 5 monedas.
          </Alert>
        );
        return;
      }
    };

    try {

      const currentDate = new Date();
      const calculatedExpirationDate = new Date();
      calculatedExpirationDate.setDate(currentDate.getDate() + parsedDays);
      setLoading(true);

      await addDoc(collection(db, 'vipDetails'), {
        username,
        Numbers: numbersArray,
        Days: parsedDays,
        Cost: parsedCost,
        Nnota: nnota,
        createdAt: serverTimestamp(),
      });

      const userDocRef = doc(db, 'users', username);
      await updateDoc(userDocRef, {
        expirationDate: calculatedExpirationDate.toISOString(),
      });

        // Actualizar el estado para deshabilitar el formulario
    setIsBlocked(true);
    setExpirationDate(calculatedExpirationDate);

      alert('¡Publicación guardada exitosamente!');
      setNumbers('');
      setDays('');
      setCost('');
      setNnota('');
      
    } catch (error) {
      console.error('Error al guardar la publicación:', error);
      setErrorMessage('Hubo un error al guardar la publicación. Inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const handleTransfer = async () => {
    if (!recipient || !amount) {
      alert('Por favor completa todos los campos.');
      return;
    }

    const transferAmount = parseInt(amount, 10);
    if (transferAmount <= 0) {
      alert('La cantidad debe ser mayor a cero.');
      return;
    }

    if (transferAmount > monOro) {
      alert('No tienes suficientes monedas para esta transferencia.');
      return;
    }

    try {
      const recipientDocRef = doc(db, 'users', recipient);
      const recipientDocSnap = await getDoc(recipientDocRef);

      if (!recipientDocSnap.exists()) {
        alert('El usuario destinatario no existe.');
        return;
      }

      setShowModal(true);
    } catch (error) {
      console.error('Error al verificar el usuario destinatario:', error);
    }
  };

  const confirmTransfer = async () => {
    try {
      const transferAmount = parseInt(amount, 10);
      setShowModal(false)
      
      if (transferAmount <= 0) {
        alert('El monto debe ser mayor a cero.');
        return;
      }
  
      const senderDocRef = doc(db, 'users', username);
      const recipientDocRef = doc(db, 'users', recipient);
  
      const senderDocSnap = await getDoc(senderDocRef);
      const recipientDocSnap = await getDoc(recipientDocRef);
  
      if (!recipientDocSnap.exists()) {
        alert('El usuario destinatario no existe.');
        return;
      }
  
      const senderData = senderDocSnap.data();
      if (senderData.monOro < transferAmount) {
        alert('No tienes suficientes monedas para realizar esta transferencia.');
        return;
      }
  
      // Actualizar saldos de ambos usuarios
      await updateDoc(senderDocRef, { monOro: senderData.monOro - transferAmount });
      const recipientData = recipientDocSnap.data();
      await updateDoc(recipientDocRef, { monOro: (recipientData.monOro || 0) + transferAmount });
  
      // Registrar la transacción en el historial del remitente
      await addDoc(collection(senderDocRef, 'CoinHistory'), {
        type: 'sent',
        amount: transferAmount,
        relatedUser: recipient,
        description: `Enviaste ${transferAmount} monedas a ${recipient}`,
        timestamp: serverTimestamp(), // Asegúrate de guardar el timestamp aquí
      });
      
      
      // Registrar la transacción en el historial del destinatario
      await addDoc(collection(recipientDocRef, 'CoinHistory'), {
        type: 'received',
        amount: transferAmount,
        relatedUser: username,
        description: `Recibiste ${transferAmount} monedas de ${username}`,
        timestamp: serverTimestamp(), // Asegúrate de guardar el timestamp aquí
      });
  
      // Resetear el estado y confirmar
      setMonOro(senderData.monOro - transferAmount);
      setRecipient('');
      setAmount('');
      alert(`Has transferido ${transferAmount} monedas a ${recipient}.`);
    } catch (error) {
      console.error('Error al realizar la transferencia:', error);
      alert('Hubo un error durante la transferencia. Inténtalo de nuevo.');
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('username');
    navigate('/');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography component="h1" variant="h5" color="primary" gutterBottom>
          Configuración del Usuario
        </Typography>
        {avatarUrl && <Avatar src={avatarUrl} sx={{ width: 100, height: 100, mt: 2 }} />}
                
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleEditProfile1}
          >
            Editar Perfil
          </Button>
        
        <Typography variant="body1" sx={{ mt: 4 }}>
          
          <strong>Nivel de Usuario:</strong> {role.charAt(0).toUpperCase() + role.slice(1)}
          
        </Typography>
       
        {createdAt && (
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>Cuenta creada el:</strong> {createdAt.toLocaleDateString()}
          </Typography>
        )}
        {expiryDate && (
          <Typography variant="body1" sx={{ mt: 1 }}>
            <strong>Cuenta expira el:</strong> {expiryDate.toLocaleDateString()}
          </Typography>
        )}
       <Typography
  variant="body1"
  sx={{
    mt: 4,
    display: 'flex',
    alignItems: 'center', // Alinear icono y texto
    gap: 1, // Espaciado entre el ícono y el texto
    fontSize: '1.2rem', // Tamaño de fuente
    fontWeight: 'bold', // Texto en negrita
    color: '#4caf50', // Color verde para el texto
    backgroundColor: '#f9f9f9', // Fondo claro para resaltar
    padding: '10px', // Relleno interno
    borderRadius: '8px', // Bordes redondeados
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Sombra ligera
  }}
>
  <MonetizationOnIcon
    sx={{
      color: '#ff9800', // Color naranja para el ícono
      fontSize: '1.5rem', // Tamaño del ícono
    }}
  />{' '}
  <strong>Monedas de Oro:</strong> {monOro}
</Typography>

<Box
  sx={{
    mt: 2,
    width: '100%',
    borderTop: '2px solid #4caf50', // Línea superior verde
    paddingTop: 2, // Espaciado interno
  }}
>
  <Button
    variant="contained"
    color="secondary"
    fullWidth
    sx={{ mt: 2 }}
    onClick={() => navigate('/coin-history')} // Redirige al historial
  >
    💸 Monedero
  </Button>

  <Button
  variant="contained"
  color="success"
  startIcon={<WhatsAppIcon />}
  onClick={() => {
    const referralLink = generateReferralLink(); // Genera el enlace de referido
    const message = `¡Hola! Te invito a unirte. Regístrate aquí: ${referralLink}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`; // Construye la URL de WhatsApp
    window.open(whatsappUrl, '_blank'); // Abre WhatsApp en una nueva ventana
  }}
  fullWidth
  sx={{ mt: 2 }}
>
  Compartir Enlace de Referido por WhatsApp
</Button>
</Box>

{numerologo && (
  <Box
  sx={{
    mt: 4,
    width: '100%',
    padding: 3,
    backgroundColor: '#f9f9f9',
    borderRadius: 2,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  }}
>
  <Typography variant="h6" color="primary" gutterBottom>
    Publicar 3 Números
  </Typography>

  {/* Mostrar mensaje si `expirationDate` es válido y aún no ha pasado */}
  {expirationDate && new Date() < expirationDate ? (
    <Typography
      variant="body2"
      sx={{
        color: '#d32f2f', // Rojo para el texto
        backgroundColor: '#ffebee', // Fondo rojo claro
        padding: 2,
        borderRadius: 2,
        mb: 2,
        fontWeight: 'bold',
        border: '1px solid #e57373', // Borde rojo
      }}
    >
      No puedes publicar nuevos números hasta que termine la publicación actual.
      Podrás publicar de nuevo después del{' '}
      <strong>{expirationDate.toLocaleDateString()}</strong>.
    </Typography>
  ) : (
    <>
      {/* Mostrar mensaje de error */}
      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            mb: 2,
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#d32f2f',
            backgroundColor: '#ffebee',
            borderRadius: '8px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          }}
        >
          {errorMessage}
        </Alert>
      )}

      {/* Formulario para publicar números */}
      <TextField
        label="Números (Coloque sus 3 mejores números)"
        fullWidth
        value={numbers}
        onChange={(e) => handleNumberInputChange(e)}
        margin="normal"
        error={!numbers} // Validación para resaltar el campo vacío
        helperText={!numbers && 'Este campo es obligatorio.'} // Mensaje de ayuda
      />
     <TextField
  label="Días (Máximo 4)"
  type="number"
  fullWidth
  value={days}
  onChange={(e) => {
    const value = parseInt(e.target.value, 10); // Convertir a número
    if (value >= 1 && value <= 5) {
      setDays(value); // Solo actualizar si está dentro del rango
    }
  }}
  margin="normal"
  inputProps={{
    min: 1, // Mínimo permitido
    max: 5, // Máximo permitido
  }}
  error={days !== '' && (parseInt(days, 10) > 5 || parseInt(days, 10) < 1)}
  helperText={
    days !== '' && (parseInt(days, 10) > 5
      ? 'No puedes asignar más de 4 días.'
      : parseInt(days, 10) < 1
      ? 'El número de días debe ser mayor que 0.'
      : '')
  }
/>
<TextField
  label="Costo en Monedas"
  type="number"
  fullWidth
  value={cost}
  onChange={(e) => {
    const value = e.target.value;

    // Validar que el valor sea un número válido
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 6) {
      setCost(parsedValue); // Actualizar estado si el valor está dentro del rango permitido
    } else if (value === '') {
      setCost(''); // Permitir que el campo quede vacío temporalmente
    }
  }}
  margin="normal"
  inputProps={{
    min: 1, // Mínimo permitido
    max: 5, // Máximo permitido
  }}
  error={cost !== '' && (parseInt(cost, 10) < 1 || parseInt(cost, 10) > 6)}
  helperText={
    cost !== '' && (parseInt(cost, 10) < 1
      ? 'El costo debe ser al menos 1 moneda.'
      : parseInt(cost, 10) > 6
      ? 'El costo máximo es 5 monedas.'
      : '')
  }
/>

      <TextField
        label="Nota (explicación de cómo jugar)"
        fullWidth
        value={nnota}
        onChange={(e) => setNnota(e.target.value)}
        margin="normal"
        multiline
        rows={3}
        error={!nnota}
        helperText={!nnota && 'Este campo es obligatorio.'}
      />

      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 3,
          padding: '10px 20px',
          fontSize: '1rem',
          borderRadius: 2,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        }}
        onClick={handleSavePublication}
      >
        Guardar Publicación
      </Button>
    </>
  )}
</Box>

)}

<Box sx={{ mt: 4, width: '100%' }}>
  <Typography variant="h6" color="primary" gutterBottom>
    Transferir Monedas
  </Typography>
  <TextField
  label="Nombre de Usuario Destinatario"
  fullWidth
  value={recipient}
  onChange={(e) => setRecipient(e.target.value.toLowerCase())} // Convertir a minúsculas
  margin="normal"
  error={recipient === username} // Mostrar error si el destinatario es el remitente
  helperText={recipient === username ? 'No puedes transferir monedas a ti mismo.' : ''} // Mensaje de ayuda
/>
<TextField
  label="Cantidad de Monedas"
  type="number"
  fullWidth
  value={amount}
  onChange={(e) => setAmount(e.target.value)}
  margin="normal"
/>
<Button
  variant="contained"
  color="primary"
  sx={{ mt: 2 }}
  onClick={handleTransfer}
  disabled={recipient === username || !recipient || !amount || parseInt(amount, 10) <= 0} // Deshabilitar si es inválido
>
  Transferir Monedas
</Button>

        </Box>

        <Modal open={showModal} onClose={() => setShowModal(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      p: 4,
      borderRadius: 2,
      boxShadow: 24,
    }}
  >
    <Typography variant="h6" gutterBottom>
      Confirmar Transferencia
    </Typography>
    <Typography sx={{ mb: 2 }}>
      ¿Estás seguro de que deseas transferir {amount} monedas a <strong>{recipient}</strong>?
    </Typography>
    <Button
      variant="contained"
      color="primary"
      sx={{ mr: 2 }}
      onClick={confirmTransfer}
      disabled={recipient === username} // Evitar confirmación si el destinatario es el remitente
    >
      Confirmar
    </Button>
    <Button variant="outlined" color="error" onClick={() => setShowModal(false)}>
      Cancelar
    </Button>
  </Box>
</Modal>
        <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
          <Button variant="outlined" color="error" onClick={handleLogout}>
            Cerrar Sesión
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/chat')}>
            Volver al Chat
          </Button>
          
        </Box>
      </Box>
    </Container>
  );
}

export default Settings;
