import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Box, TextField, Button, Typography, Container, FormControlLabel, Checkbox, IconButton, Alert } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import SnowflakeIcon from '@mui/icons-material/AcUnit';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');
  const [maintenanceMessage, setMaintenanceMessage] = useState(false);
  const [forgotMode, setForgotMode] = useState(false);
  const [recoveredCredentials, setRecoveredCredentials] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedUsername = localStorage.getItem('rememberedUsername');
    const savedPassword = localStorage.getItem('rememberedPassword');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }

    const backgroundImages = [
      'https://img.freepik.com/fotos-premium/fondo-festivo-articulos-decoracion-navidad_9693-868.jpg',
      'https://img.freepik.com/fotos-premium/arbol-navidad-rojo-fondo-rojo-luces_955834-56394.jpg',
      'https://wallpapers.com/images/featured/3d-navidad-bw37ez6p9clxblpj.jpg',
      'https://www.todofondos.net/wp-content/uploads/72-fondos-de-navidad-azules.-wallpaper-hd-1080p-navidenos-1024x576.jpg',
      'https://resources.diariolibre.com/images/2021/12/24/imagen-231221-venta-de-cerdo-sto-dgo-este-07-3ee24939-focus-0-0-608-342.jpg',
      'https://concepto.de/wp-content/uploads/2015/05/Navidad-e1547047735822.jpg',
      'https://cdn.pixabay.com/photo/2016/11/29/13/37/christmas-1869902_1280.jpg'
    ];
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setBackgroundImage(backgroundImages[randomIndex]);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setMaintenanceMessage(false);
    setError('');

    try {
      const normalizedUsername = username.trim().toLowerCase();
      const userDocRef = doc(db, 'users', normalizedUsername);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        setError('El usuario no existe');
        return;
      }

      const userData = userDoc.data();

      if (userData.isBlocked) {
        setError('Su cuenta ha sido bloqueada. No puede acceder al chat.');
        return;
      }

      if (userData.password !== password) {
        setError('Contraseña incorrecta');
        return;
      }

      if (userData.role === 'normall') {
        setMaintenanceMessage(true);
      } else {
        if (rememberMe) {
          localStorage.setItem('rememberedUsername', normalizedUsername);
          localStorage.setItem('rememberedPassword', password);
        } else {
          localStorage.removeItem('rememberedUsername');
          localStorage.removeItem('rememberedPassword');
        }
        localStorage.setItem('username', normalizedUsername);
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Error al iniciar sesión. Inténtalo de nuevo.');
    }
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = "https://wa.me/18298415339?text=3";
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          p: 4,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 3,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          position: 'relative',
        }}
      >
        <HolidayVillageIcon
          sx={{
            fontSize: 60,
            color: 'green',
            position: 'absolute',
            top: -30,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: '#d32f2f',
            fontFamily: 'Pacifico, cursive',
          }}
        >
          ¡Felices Fiestas!
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        {maintenanceMessage && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Este chat está en mantenimiento para usuarios con rol "normal". Contacta a soporte para obtener más información.
          </Alert>
        )}
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nombre de usuario"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
            sx={{ bgcolor: 'white', borderRadius: 1 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="La Clave es de 4 números"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{ maxLength: 4 }}
            sx={{ bgcolor: 'white', borderRadius: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                sx={{ color: 'green' }}
              />
            }
            label="Recordar mis datos"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: '#d32f2f',
              color: 'white',
              '&:hover': { bgcolor: '#c62828' },
              fontWeight: 'bold',
            }}
          >
            Acceso
          </Button>
        </Box>
        <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={() => navigate('/')}
          >
            Volver al Inicio
          </Button>
        <IconButton color="primary" sx={{ mt: 2 }} onClick={handleWhatsAppClick}>
          <WhatsAppIcon sx={{ fontSize: 40, color: '#25D366' }} />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
          Contáctanos por WhatsApp
        </Typography>
        <SnowflakeIcon sx={{ fontSize: 30, color: '#d32f2f', mt: 2 }} />
      </Box>
      
    </Container>
  );
}

export default Login;
