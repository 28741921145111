import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, getDocs, addDoc, doc, deleteDoc, where, serverTimestamp, getDoc, updateDoc,setDoc   } from 'firebase/firestore';


import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BuyCoinsDialog from './BuyCoinsDialog';

function CoinHistory() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalReceived, setTotalReceived] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const username = localStorage.getItem('username');
  const navigate = useNavigate();
  const targetAmount = 50; // Meta de monedas
  const [isBuyCoinsDialogOpen, setIsBuyCoinsDialogOpen] = useState(false);
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Obtener datos del usuario
        const userDocRef = doc(db, 'users', username);
        const userDoc = await getDoc(userDocRef);
        const userMonOro = userDoc.exists() ? userDoc.data().monOro || 0 : 0;
  
        // Obtener transacciones del historial
        const transactionsQuery = query(
          collection(db, `users/${username}/CoinHistory`)
        );
        const snapshot = await getDocs(transactionsQuery);
  
        const transactionsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp
            ? new Date(data.timestamp.seconds * 1000) // Si `timestamp` existe
            : data.vipAccess
            ? new Date(data.vipAccess.seconds * 1000) // Si `vipAccess` existe
            : null;
  
          return {
            id: doc.id,
            ...data,
            timestamp,
          };
        });
  
        // Ordenar transacciones por fecha en orden descendente
        const sortedTransactions = transactionsData.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            return b.timestamp - a.timestamp;
          }
          return 0;
        });
  
        setTransactions(sortedTransactions);
  
        // Calcular total recibido en los últimos 30 días
        const now = new Date();
        const last30Days = new Date(now.setDate(now.getDate() - 30));
  
        const total = sortedTransactions.reduce((sum, transaction) => {
          // Sumar ingresos generales (VIP incluido)
          if (
            ['received', 'Deposit', 'Credit', 'VIP Earning'].includes(transaction.type) &&
            transaction.timestamp &&
            transaction.timestamp >= last30Days
          ) {
            return sum + transaction.amount;
          }
          // Restar gastos de VIP
          if (
            transaction.type === 'VIP Spending' &&
            transaction.timestamp &&
            transaction.timestamp >= last30Days
          ) {
            return sum - transaction.amount;
          }
  
          return sum;
        }, userMonOro);
  
        // Establecer el total recibido usando monOro
        setTotalReceived(userMonOro);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setErrorMessage('Hubo un error al cargar las transacciones.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchTransactions();
  }, [username]);
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const progress = Math.min((totalReceived / 50) * 100, 100); // Calcular progreso en porcentaje (máximo 100%)
  

  const handleRequestPayment = async () => {


      // Validar si la fecha actual está entre el 25 y el 30 del mes
  const today = new Date();
  const dayOfMonth = today.getDate();
  if (dayOfMonth < 25 || dayOfMonth > 30) {
    alert("Solo puedes retirar entre el 25 y el 30 de cada mes.");
    return;
  }

  if (totalReceived <= 0) {
    alert("No tienes monedas para retirar.");
    return;
  }
    try {
      const userDocRef = doc(db, "users", username);
      const coinHistoryRef = collection(userDocRef, "CoinHistory");

      // Obtener todos los registros actuales y eliminarlos
      const snapshot = await getDocs(coinHistoryRef);
      const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

       // Verificar si el retiro es superior a 30 monedas
  let finalAmount = totalReceived;
  let commission = 0;

  if (totalReceived > 30) {
    commission = parseFloat((totalReceived * 0.075).toFixed(2)); // Calcular el 7.5%
    finalAmount = parseFloat((totalReceived - commission).toFixed(2)); // Resto después de la comisión

    // Guardar la comisión en la colección "fondos"
    const fondosRef = doc(db, "sistema", "fondos");
    const fondosDoc = await getDoc(fondosRef);

    if (fondosDoc.exists()) {
      const currentFondos = fondosDoc.data().totalFondos || 0;
      await updateDoc(fondosRef, { totalFondos: currentFondos + commission });
    } else {
      // Si el documento de fondos no existe, crearlo
      await setDoc(fondosRef, { totalFondos: commission });
    }


    // Registrar la comisión en CoinHistory
    await addDoc(coinHistoryRef, {
      type: 'commission',
      amount: commission,
      relatedUser: 'Sistema',
      description: `Comisión por retiro de fondos superior a 30 monedas (${commission} monedas).`,
      timestamp: serverTimestamp(),
    });
  }

          // Actualizar monOro a cero
    await updateDoc(userDocRef, { monOro: 0 });
  
      // Agregar un nuevo registro con el mensaje solicitado
       await addDoc(coinHistoryRef, {
    type: "withdrawal", // Tipo de transacción
    amount: finalAmount, // Monto final retirado después de la comisión
    relatedUser: "Sistema",
    description: `Retiraste ${finalAmount} monedas después de una comisión de ${commission} monedas.`,
    fcharetiro: serverTimestamp(), // Registrar la fecha del retiro
    timestamp: serverTimestamp(),
  });

      await addDoc(coinHistoryRef, {
        type: 'received',
        amount: 0,
        
        timestamp: serverTimestamp(), // Genera automáticamente el timestamp
        relatedUser: 'sistema',
        description: 'Su balance esta en cero'
      });
      
  
      // Reiniciar el balance recibido
      setTotalReceived(0);
      
      alert("Tu solicitud de pago ha sido procesada con éxito.");
      window.location.reload();
    } catch (error) {
      console.error("Error al procesar la solicitud de pago:", error);
      alert(
        "Hubo un error al procesar tu solicitud de pago. Verifica tu conexión a internet y los permisos de Firestore."
      );
    }
  };
  

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
  <Typography variant="h4" gutterBottom>
    Historial de Monedas
  </Typography>
  <Typography variant="h6" sx={{ mt: 2, color: '#4caf50' }}>
    Total Recibido (últimos 30 días): <strong>{totalReceived} monedas</strong>
  </Typography>
  {successMessage && (
    <Alert severity="success" sx={{ my: 2 }}>
      {successMessage}
    </Alert>
  )}
  {errorMessage && (
    <Alert severity="error" sx={{ my: 2 }}>
      {errorMessage}
    </Alert>
  )}
  {transactions.length === 0 ? (
    <Typography variant="body1">
      No tienes transacciones registradas.
    </Typography>
  ) : (
    <TableContainer
      component={Paper}
      sx={{
        mt: 4,
        borderRadius: '12px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
      }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: '#1976d2' }}>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Fecha
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Tipo
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Monto
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Usuario Relacionado
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Descripción
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction, index) => (
            <TableRow
              key={transaction.id}
              sx={{
                bgcolor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                '&:hover': { bgcolor: '#e3f2fd' },
              }}
            >
              <TableCell
                sx={{
                  fontSize: '14px',
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                {transaction.timestamp
                  ? transaction.timestamp.toLocaleString()
                  : transaction.vipAccess
                  ? transaction.vipAccess.toLocaleString()
                  : 'Fecha no disponible'}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '14px',
                  color: transaction.type === 'VIP' ? '#ffa726' : '#333',
                  fontWeight: transaction.type === 'VIP' ? 'bold' : 'normal',
                  textAlign: 'center',
                }}
              >
                {transaction.type === 'VIP'
                  ? 'Acceso VIP'
                  : transaction.type === 'received'
                  ? 'Recibido'
                  : transaction.type === 'sent'
                  ? 'Enviado'
                  : 'Recarga'}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '14px',
                  color: transaction.amount > 0 ? '#388e3c' : '#d32f2f',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {transaction.amount}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '14px',
                  color: transaction.relatedUser === 'Sistema' ? '#0288d1' : '#333',
                  fontWeight: transaction.relatedUser === 'Sistema' ? 'bold' : 'normal',
                  textAlign: 'center',
                }}
              >
                {transaction.relatedUser === 'Sistema' ? (
                  <strong>Sistema</strong>
                ) : (
                  transaction.relatedUser || '-'
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '14px',
                  color: transaction.type === 'notification' ? '#d32f2f' : '#333',
                  fontWeight: transaction.type === 'notification' ? 'bold' : 'normal',
                  textAlign: 'center',
                }}
              >
                {transaction.type === 'notification' ? (
                  <span style={{ color: '#d32f2f', fontWeight: 'bold' }}>
                    {transaction.description}
                  </span>
                ) : (
                  transaction.description
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}
</Box>
    <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <Box
        sx={{
          mt: 4,
          p: 3,
          borderRadius: 3,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          background: 'linear-gradient(135deg, #e3f2fd, #ffffff)',
        }}
      >
        <Typography
          sx={{
            mb: 2,
            fontWeight: 'bold',
            fontSize: '18px',
            color: progress === 4 ? '#4caf50' : '#1976d2',
          }}
        >
          {totalReceived} / {targetAmount} monedas recolectadas
        </Typography>

        {/* Barra de progreso personalizada */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '20px',
            backgroundColor: '#e0e0e0',
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.2)',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: `${progress}%`,
              background:
                progress === 3
                  ? 'linear-gradient(90deg, #66bb6a, #43a047)'
                  : 'linear-gradient(90deg, #42a5f5, #1e88e5)',
              transition: 'width 0.5s ease',
            }}
          />
        </Box>

        <Typography
          sx={{
            mt: 2,
            fontWeight: 'bold',
            fontSize: '16px',
            color: progress === 20 ? '#4caf50' : '#616161',
          }}
        >
          {progress === 50
            ? '¡Felicidades! Has alcanzado la meta.'
            : 'Sigue recolectando monedas para alcanzar la meta.'}
        </Typography>
      </Box>

      
    </Container>
 


      {/* Botones */}
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => setIsDialogOpen(true)}
          disabled={totalReceived < 20} // Deshabilitar el botón si totalReceived es <= 0
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            px: 4,
            py: 1,
            borderRadius: '8px',
            mr: 2,
          }}
        >
          Solicitar Pago
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/settings')}
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            px: 4,
            py: 1,
            borderRadius: '8px',
            mr: 2,
          }}
        >
          Volver a Configuración
        </Button>

         
          <Button
            variant="contained"
            color="warning"
            onClick={() => setIsBuyCoinsDialogOpen(true)}
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              px: 4,
              py: 1,
              borderRadius: '8px',
            }}
          >
            Comprar Monedas
          </Button>
        
        
      </Box>

{/* Diálogo de confirmación */}
<Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
  <DialogTitle>Confirmar Solicitud de Pago</DialogTitle>
  <DialogContent>
    {totalReceived === 0 ? (
      // Mostrar mensaje si el balance es cero
      <Typography>
        No tienes monedas para retirar. Verifica nuevamente tus transacciones.
      </Typography>
    ) : (
      // Mostrar opciones de confirmación si el balance es mayor a cero
      <>
        <Typography variant="h6" gutterBottom>
          ¡Retiro de monedas!
        </Typography>
        <Typography>
          Total a retirar: <strong>{totalReceived} monedas</strong>
        </Typography>
        <Typography>
          Equivalente en pesos: <strong>{(totalReceived * 100).toLocaleString('es-DO', {
            style: 'currency',
            currency: 'DOP',
            minimumFractionDigits: 2,
          })}</strong>
        </Typography>
        <Typography>
          ¿Estás seguro de que deseas retirar todas tus monedas?
        </Typography>
        <Typography sx={{ mt: 2, fontSize: '0.9rem', color: 'gray' }}>
          El depósito se realizará directamente a su cuenta bancaria como estableció. Este depósito tarda de <strong>1 a 36 horas laborables</strong>. Se aplicará una comisión por concepto de transferencias.
        </Typography>
      </>
    )}
  </DialogContent>
  <DialogActions>
    {totalReceived === 0 ? (
      // Mostrar solo botón de cerrar si no hay balance
      <Button onClick={() => setIsDialogOpen(false)} color="primary">
        Cerrar
      </Button>
    ) : (
      // Mostrar botones de confirmar/cancelar si hay balance
      <>
        <Button onClick={() => setIsDialogOpen(false)} color="error">
          Cancelar
        </Button>
        <Button onClick={handleRequestPayment} color="primary">
          Confirmar
        </Button>
      </>
    )}
  </DialogActions>
</Dialog>

<BuyCoinsDialog
        open={isBuyCoinsDialogOpen}
        onClose={() => setIsBuyCoinsDialogOpen(false)}
        username={username}
      />

    </Container>
    
  );
  
}

export default CoinHistory;
