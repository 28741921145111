import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, doc, getDoc, setDoc, onSnapshot, query, orderBy, addDoc } from 'firebase/firestore';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


function Dashboard() {
  const [username, setUsername] = useState('');
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isBalanceLoad, setIsBalanceLoad] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showMonitor, setShowMonitor] = useState(false);
  const [monitorData, setMonitorData] = useState([]);
  
 

  // Manejo del menú
  const openMenu = (event) => setMenuAnchor(event.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  // Cargar datos del monitor en tiempo real
  useEffect(() => {
    if (showMonitor) {
      const q = query(collection(db, 'CoinHistory'), orderBy('timestamp', 'desc'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMonitorData(data);
      });

      return () => unsubscribe();
    }
  }, [showMonitor]);

  // Mostrar vista del monitor
  const handleShowMonitor = () => {
    closeMenu();
    setShowMonitor(true);
  };

  // Mostrar vista de recarga
  const handleShowRecharge = () => {
    closeMenu();
    setShowMonitor(false);
  };

  // Función para recargar balance
  const handleRecharge = async () => {
    setMessage('');
    setError('');
  
    // Validaciones de entrada
    if (!username || !amount || isNaN(amount) || amount <= 0) {
      setError('Por favor, ingresa un nombre de usuario y una cantidad válida.');
      return;
    }
  
    try {
      setLoading(true);
  
      if (isBalanceLoad) {
        // Cargar balance desde los fondos del sistema
        const sistemaFondosRef = doc(db, 'sistema', 'fondos');
        const sistemaFondosSnap = await getDoc(sistemaFondosRef);
  
        if (!sistemaFondosSnap.exists()) {
          throw new Error('No se encontraron fondos del sistema.');
        }
  
        const sistemaData = sistemaFondosSnap.data();
        const sistemaFondosActuales = sistemaData.fondos || 0;
  
        if (sistemaFondosActuales < parseFloat(amount)) {
          throw new Error('Fondos insuficientes en el sistema.');
        }
  
        // Actualizar balance del sistema
        await setDoc(sistemaFondosRef, {
          ...sistemaData,
          fondos: sistemaFondosActuales - parseFloat(amount),
        });
      }
  
      // Referencia al documento del usuario
      const userDocRef = doc(db, 'users', username);
      const userDocSnap = await getDoc(userDocRef);
  
      if (!userDocSnap.exists()) {
        throw new Error(`El usuario ${username} no existe.`);
      }
  
      const userData = userDocSnap.data();
      const currentBalance = userData.monOro || 0;
  
      // Actualizar el balance del usuario
      const newBalance = currentBalance + parseFloat(amount);
      await setDoc(userDocRef, { ...userData, monOro: newBalance });
  
      // Registrar la transacción en el historial de monedas
      await addDoc(collection(db, `users/${username}/CoinHistory`), {
        type: 'Deposit',
        amount: Number(amount),
        timestamp: new Date(),
        description: note || 'Recarga de saldo',
        relatedUser: 'Sistema',
      });
  
      setMessage(
        `Depósito realizado exitosamente. Nuevo balance: ${newBalance} monedas.`
      );
      setUsername('');
      setAmount('');
      setNote('');
      setIsBalanceLoad(false);
    } catch (error) {
      console.error('Error al realizar el depósito:', error);
      setError('Hubo un error al realizar el depósito. Inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      {/* Menú de Navegación */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <IconButton color="inherit" onClick={openMenu}>
            <MenuIcon />
          </IconButton>
          <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeMenu}>
            <MenuItem onClick={handleShowRecharge}>Recargar Balance</MenuItem>
            <MenuItem onClick={handleShowMonitor}>Monitor</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Vista del Monitor */}
      {showMonitor ? (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', color: '#1976d2' }}>
            Monitor de Referidos
          </Typography>
          {monitorData.length === 0 ? (
            <Typography align="center" sx={{ py: 5 }}>
              No hay datos disponibles.
            </Typography>
          ) : (
            <List>
              {monitorData.map((item) => (
                <React.Fragment key={item.id}>
                  <ListItem>
                    <Avatar>{item.referrer?.charAt(0).toUpperCase()}</Avatar>
                    <ListItemText
                      primary={`Referido por: ${item.referrer || 'Desconocido'} - Referido: ${
                        item.referred || 'Desconocido'
                      }`}
                      secondary={`Fecha: ${new Date(
                        item.timestamp?.seconds * 1000
                      ).toLocaleString()}`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      ) : (
        // Vista de Recarga
        <Box
          sx={{
            mt: 4,
            padding: 3,
            backgroundColor: '#f0f4f8',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#1976d2' }}>
            Recargar Balance
          </Typography>

          {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

          <TextField
            label="Nombre de Usuario"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Cantidad de Monedas"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Fecha de Recarga"
            type="date"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Nota"
            fullWidth
            value={note}
            onChange={(e) => setNote(e.target.value)}
            margin="normal"
            multiline
            rows={3}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={isBalanceLoad}
                onChange={(e) => setIsBalanceLoad(e.target.checked)}
                color="primary"
              />
            }
            label="Cargar Balance desde Fondos del Sistema"
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
            onClick={handleRecharge}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Recargar Balance'}
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default Dashboard;
