import React, { useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';  // Asegúrate de que la configuración de Firebase está bien importada

function Home() {
  const navigate = useNavigate();
  const username = localStorage.getItem('username');  // Obtener el nombre de usuario almacenado

  // Función para actualizar lastOnline
  const updateLastOnline = async () => {
    if (username) {
      try {
        // Referencia al documento del usuario en Firestore
        const userDocRef = doc(db, 'users', username);  
        
        // Actualizar el campo lastOnline con la fecha y hora actual
        await updateDoc(userDocRef, {
          lastOnline: serverTimestamp(),  // Actualiza con la hora actual del servidor
        });
        console.log('lastOnline actualizado con éxito');
      } catch (error) {
        console.error('Error al actualizar lastOnline:', error);
      }
    } else {
      console.log("No se encontró el usuario en localStorage");
    }
  };

  // Llamar a la función para actualizar lastOnline cuando el componente se monte
  useEffect(() => {
    updateLastOnline();
  }, []);  // Se ejecuta solo una vez al montar el componente

  // Navegación para registrarse
  const handleSignUp = () => {
    navigate('/signup');
  };

  // Navegación para iniciar sesión
  const handleLogin = () => {
    navigate('/login');
  };

  // Función para abrir el video en una nueva pestaña
  const handleYouTubeLink = () => {
    window.open('https://www.youtube.com/watch?v=0mNO1MEcu7E', '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Chat SRD - Interactúa sobre Loterías Dominicanas</title>
        <meta name="description" content="Chat SRD es un chat interactivo para hablar sobre loterías dominicanas, interactuar libremente con otros jugadores, ver números recomendados y sorteos de loterías." />
        <meta name="keywords" content="chat SRD, loterías dominicanas, números recomendados, sorteos, jugadores, interactuar, chat interactivo" />
      </Helmet>

      <Container maxWidth="sm">
        <Box 
          sx={{ 
            mt: 8, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' 
          }}
        >
          {/* Logo en la parte superior */}
          <Box sx={{ mb: 4 }}>
            <img 
              src="https://chatsrd.com/logo.png" 
              alt="Logo de Chat SRD" 
              style={{ width: '300px', height: 'auto' }} 
            />
          </Box>

          <Typography component="h1" variant="h4" gutterBottom>
          Bienvenidos a la Comunidad de Loterías
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            Por favor regístrate o inicia sesión para continuar
          </Typography>
          
          {/* Botones de registro e inicio de sesión */}
          <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
            <Button 
              variant="contained" 
              onClick={handleSignUp}
              sx={{ width: '150px' }}
            >
              Regístrate
            </Button>
            <Button 
              variant="outlined" 
              onClick={handleLogin}
              sx={{ width: '150px' }}
            >
              Acceso
            </Button>
          </Box>

          {/* Botón para enlace a video de YouTube */}
          <Box sx={{ mt: 4 }}>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={handleYouTubeLink}
              sx={{ width: '250px' }}
            >
              Ver Tutorial en YouTube
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Home;
